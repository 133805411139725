.application-wrapper {
  margin: 2rem 0rem; }

.application-wrapper .upload-excel-button {
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 1px solid #058373;
  color: #058373;
  background-color: transparent;
  cursor: pointer; }

.application-wrapper .tick_icon {
  background-color: #4687f4;
  border-radius: 50%;
  padding: 0.5rem;
  margin-left: 0.5rem;
  height: 1.5rem; }

.application-wrapper .icon {
  height: 1.6rem;
  margin-bottom: 0.4px; }

.application-wrapper .icon_upload {
  height: 2.5rem;
  margin-bottom: 0.4px;
  margin-left: -1rem;
  margin-top: -3.5rem;
  cursor: pointer;
  background-color: #ecf1f9;
  border-radius: 50%; }

.application-wrapper .save-comments {
  color: #4687f4;
  border: 1px solid #4687f4;
  padding: 0rem 1rem;
  border-radius: 1.5rem; }

.application-wrapper .comments_text-area {
  height: 8rem;
  font-size: 1.2rem;
  font-family: 'Heebo';
  padding: 1rem; }

.application-wrapper .comments_text-area:focus {
  outline: 1px solid #f5f5f5;
  background-color: #f5f5f5; }

.application-wrapper .accounts-container {
  display: flex;
  justify-content: space-between; }

.application-wrapper .accounts-container .width {
  width: 30%; }

.application-wrapper .container-box {
  background-color: #fff;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.16);
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem; }

.application-wrapper .m-20 {
  margin: 15px 0px; }

.application-wrapper .m-10 {
  margin: 5px 0px; }

.application-wrapper .bank_Details {
  height: 33px; }

.application-wrapper .container-height {
  height: 21rem; }

.application-wrapper .business_images .img_left {
  width: 40%;
  position: absolute;
  left: 0px;
  height: 15rem;
  margin-left: 3rem;
  border-radius: 1rem; }

.application-wrapper .business_images .img_right {
  width: 40%;
  position: absolute;
  cursor: pointer;
  right: 0px;
  height: 15rem;
  margin-right: 4rem;
  border-radius: 1rem;
  background-image: linear-gradient(to right bottom, #000000, #222020, #3d3b39, #595854, #757772); }

.application-wrapper .business_images .img_right .view_more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 12rem;
  text-align: center; }

.application-wrapper .business_images_default {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #a4a4a4;
  font-weight: bold;
  height: 15rem;
  width: 90%;
  font-size: 1.8rem; }

.application-wrapper .sms_excel {
  cursor: pointer; }

.application-wrapper .sms_excel img {
  height: 3rem;
  margin-right: 1rem; }

.application-wrapper .sms_excel span {
  color: #4687f4; }

.application-wrapper .text-black {
  font-family: 'Heebo';
  font-size: 1.2rem;
  margin: 1.5rem 1rem; }

.application-wrapper .text-black_heading {
  height: 3.5rem; }

.application-wrapper .container-sub {
  display: flex;
  justify-content: space-between; }

.application-wrapper .container-sub_min {
  width: 49.3%;
  font-family: 'Heebo'; }

.application-wrapper .container-sub_min span {
  font-size: 1.2rem;
  cursor: pointer; }

.application-wrapper .button_green {
  background: #cdefeb;
  border-radius: 3rem;
  color: #267e72;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.application-wrapper .bank_icon {
  align-items: center;
  display: flex;
  justify-content: center; }

.application-wrapper .bank_icon_img {
  height: 3rem; }

.application-wrapper .phone_img {
  background-color: #4687f4;
  padding: 0.5rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem; }

.application-wrapper .map_img {
  height: 4rem;
  cursor: pointer; }

.application-wrapper .map_text {
  color: #adadad;
  font-size: 1rem;
  margin-left: 1rem; }

.application-wrapper .pen_img {
  height: 1.5rem;
  cursor: pointer; }

.application-wrapper .human_img {
  height: 15rem;
  width: 12rem;
  cursor: pointer;
  border-radius: 1rem;
  box-shadow: 0px 5px 10px rgba(156, 155, 155, 0.16); }

.application-wrapper .promotors-container {
  border-right: 2px solid rgba(68, 114, 196, 0.1); }

.application-wrapper .promoters_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.application-wrapper .promoters_image_documents {
  border-radius: 3rem;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;
  border: none; }

.application-wrapper .promoters_image_documents:disabled {
  cursor: not-allowed; }

.application-wrapper .promoters_image_details {
  color: #4687f4;
  background-color: #ecf1f9; }

.application-wrapper .promoters_image_verify_details {
  color: #058373;
  background-color: #ecf9f1; }

.application-wrapper .promoters_image_dedupe_failed {
  color: #ca4949;
  background-color: #fac4bd; }

.application-wrapper .promoters_image_dedupe_success {
  color: #058373;
  background-color: #c3f9eb; }

.application-wrapper .bank_account_button {
  color: #404040;
  margin-right: 1rem;
  text-align: center;
  font-family: 'Heebo';
  padding: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer; }

.application-wrapper .bank_account_active {
  background: #f4f7fb;
  border: 1px solid #4687f4;
  border-radius: 2rem;
  color: #4687f4;
  width: 20%; }

.application-wrapper .bank_container {
  display: flex;
  justify-content: space-between; }

.application-wrapper .bank_container_box {
  width: 32%; }

.application-wrapper .bank_sub_title {
  color: #4687f4;
  font-size: 2rem; }

.application-wrapper .bank_value_red {
  font-family: Heebo;
  color: #ca4949;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  align-items: center;
  display: flex; }

.application-wrapper .decision_buttons {
  display: flex;
  justify-content: center;
  margin: 3rem 0rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: white; }

.application-wrapper .reject_button {
  background-color: #ca4949;
  display: flex;
  justify-content: center;
  width: 20rem;
  border-radius: 5px;
  align-items: center; }

.application-wrapper .reject_button .reject_image {
  cursor: pointer; }

.application-wrapper .reject_button .icon_img {
  height: 2.5rem;
  margin-right: 1rem;
  background-color: #ca4949; }

.application-wrapper .active_button {
  background-color: #4687f4;
  border-radius: 5px;
  text-align: center;
  padding: 1rem 0rem;
  width: 20rem;
  margin-right: 5rem; }

.application-wrapper .phone_value {
  color: #4687f4;
  font-weight: bold;
  font-size: 1.5rem; }

.application-wrapper .compare_img {
  height: 1.8rem;
  cursor: pointer;
  margin-right: 1rem; }

.application-wrapper .entity_height {
  height: 26%; }

.application-wrapper .grey {
  background-color: #f5f5f5;
  width: 100%;
  margin-top: 3rem; }

.application-wrapper .center {
  width: fit-content;
  float: left; }

.application-wrapper .entity_container {
  font-size: 1.4rem; }

.application-wrapper .entity_container_cibil_heading {
  text-decoration: underline;
  font-size: 1.2rem;
  color: #4687f4;
  cursor: pointer; }

.application-wrapper .entity_container_cibil_score {
  border: 1px solid #4687f4;
  border-radius: 2rem;
  height: 4rem;
  font-size: 1rem;
  background-color: #f4f7fb;
  padding: 0.5rem 0rem;
  font-family: 'Heebo'; }

.application-wrapper .entity_container_cibil_score_value {
  color: #058373;
  font-weight: bold;
  font-size: 1.5rem; }

.application-wrapper .entity_container_title {
  font-size: 1.75rem;
  color: #058373;
  font-weight: bold; }

.application-wrapper .entity_container_heading {
  color: #adadad;
  font-family: 'Heebo'; }

.application-wrapper .entity_container_margin {
  margin-top: 2rem; }

.application-wrapper .entity_container_value {
  font-weight: bold;
  font-size: 1.4rem;
  font-family: 'Heebo'; }

.application-wrapper .entity_container_bold {
  font-weight: bold;
  font-family: 'Heebo'; }

.application-wrapper .entity_container_sub-value {
  font-weight: 400;
  font-family: 'Heebo'; }

.application-wrapper .entity_container .blue-tik {
  height: 2rem;
  margin-left: 2rem; }

.application-wrapper .limit {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right; }

.application-wrapper .limit_container_title {
  color: #ffffff;
  font-weight: bold;
  font-size: 2rem; }

.application-wrapper .limit_container_text {
  color: #4adfcb;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 5%;
  font-family: 'Heebo'; }

.application-wrapper .limit_container_limit {
  color: white;
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Heebo'; }

.application-wrapper .icon {
  height: 2rem;
  margin-left: 1rem; }

.dedupe-details-wrapper .icon {
  height: 2.5rem;
  margin: 0rem 1rem; }

.dedupe-details-wrapper .dedupe {
  text-align: right;
  margin: 4rem 0rem; }

.dedupe-details-wrapper .dedupe_failed {
  color: #ca4949; }

.dedupe-details-wrapper .dedupe_success {
  color: #058373; }

.ckyc-wrapper .margin {
  margin-top: 2rem; }

.ckyc-wrapper .card__wrapper {
  border-radius: 15px;
  border: 1px solid #bcbcbc;
  margin: 2%; }

.ckyc-wrapper .card__wrapper .card-heading {
  border-bottom: 1px solid #bcbcbc;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #058373; }

.ckyc-wrapper .card__wrapper .card-heading__icon {
  height: 2rem;
  margin-left: 1rem; }

.ckyc-wrapper .card__wrapper .card-body {
  padding: 5%; }

.ckyc-wrapper .card__wrapper .img {
  margin-right: 2rem; }

.ckyc-wrapper .card__wrapper .cursor {
  cursor: pointer; }

.ckyc-wrapper .card__wrapper .height-minimum {
  margin-top: 0; }

.offline_wrapper {
  align-items: center;
  flex-direction: column;
  margin-top: 3rem; }

.offline_wrapper .card-body {
  padding: 5%;
  display: flex;
  justify-content: space-between; }

.kyc_modal_wrapper {
  width: 90%;
  margin-left: 3rem; }

.kyc_modal_wrapper .center {
  justify-content: space-between;
  width: 108%; }

.kyc_modal_wrapper .digilocker_card_outer {
  padding: 0; }

.kyc_modal_wrapper .digilocker_card_inner {
  margin: 0 0;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-radius: 0; }

.pg-viewer-wrapper img {
  height: 60rem;
  width: auto; }

*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

iframe {
  display: none; }

html {
  font-size: 62.5%; }

body {
  box-sizing: border-box;
  background-color: #f2f3f7;
  font-family: 'Poppins';
  font-size: 1.4rem; }

a:link,
a:visited,
a:link:active,
a:visited:active {
  cursor: pointer;
  text-decoration: none; }

.container {
  max-width: 1285px; }

.flex {
  display: flex;
  align-items: center; }

.card {
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.16);
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  margin: 2rem 0rem;
  border: none; }

.card_heading {
  color: #058373;
  font-weight: bold;
  margin: 3rem 0rem 1rem 0rem; }

.card_heading_secondary {
  color: #058373;
  font-weight: bold;
  margin: 2rem 0rem; }

.card_heading_secondary_right {
  color: #bfbfbf;
  font-weight: bold;
  font-family: 'Heebo';
  text-align: right; }

.card_add_button {
  border-radius: 0.5rem;
  width: 16rem;
  height: 4rem;
  font-weight: bolder;
  color: #4687f4;
  border: 2px solid #4687f4;
  background-color: white; }

.card_add_button:hover {
  color: white;
  border: 2px solid #4687f4;
  background-color: #4687f4; }

.card_add_button:disabled {
  cursor: not-allowed; }

.text-grey {
  color: #adadad;
  font-family: 'Heebo';
  font-size: 1.4rem;
  margin: 1rem 0rem; }

.text-grey input[type='radio'] {
  margin-right: 10px;
  width: auto; }

.text-black {
  color: black;
  font-family: 'Heebo';
  font-size: 1.3rem;
  margin: 2rem 0rem 0rem; }

.text-black input[type='radio'] {
  margin-right: 10px;
  width: auto; }

.text-bold {
  color: #1d1d1d;
  font-weight: bolder;
  font-family: 'Heebo';
  padding: 0.5rem 0rem; }

.ReactTable {
  border: none !important;
  border-radius: 1.5rem 1.5rem 0rem 0rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.03);
  background-color: white; }

.ReactTable .rt-thead .rt-tr {
  text-align: left; }

.ReactTable .rt-thead.-header {
  border-radius: 1rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.03) !important;
  min-width: 200px;
  padding: 1rem 1.5rem;
  margin-bottom: 1.4rem;
  background-color: #ffffff; }

.ReactTable .-sort-desc {
  box-shadow: none !important; }

.ReactTable .-sort-desc::after {
  content: ' ' !important;
  float: right; }

.ReactTable .-sort-asc {
  box-shadow: none !important; }

.ReactTable .-sort-asc::after {
  content: ' ' !important;
  float: right; }

.ReactTable .rt-table {
  border-radius: 1rem 1rem 0rem 0rem; }

.ReactTable .rt-table::-webkit-scrollbar {
  height: 1rem; }

.ReactTable .rt-table::-webkit-scrollbar-button {
  display: none; }

.ReactTable .rt-table::-webkit-scrollbar-track {
  background: transparent; }

.ReactTable .rt-table::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #001061; }

.ReactTable .rt-th {
  font-size: 1.3rem;
  font-family: 'Heebo';
  color: #adadad;
  border-right: none !important;
  padding: 13px 5px;
  text-align: left;
  letter-spacing: 0.4px; }

.ReactTable .rt-th.-sort-asc::after {
  content: '\f0d7';
  position: absolute;
  right: 9px;
  top: 10px;
  font-size: 16px; }

.ReactTable .rt-th.-sort-desc::after {
  content: '\f0d8';
  position: absolute;
  right: 9px;
  top: 10px;
  font-size: 16px; }

.ReactTable .rt-tbody {
  border-radius: 1rem 1rem 0px 0px; }

.ReactTable .rt-tbody .rt-tr {
  cursor: pointer; }

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: none !important;
  min-height: 5rem;
  padding: 0rem 1.5rem; }

.ReactTable .rt-tbody .rt-tr .rt-td {
  white-space: normal;
  border-right: none !important;
  font-size: 1.4rem;
  font-family: 'Heebo'; }

.ReactTable .rt-tbody .rt-tr .rt-td .edit-icon {
  color: #5f5f5f;
  border-bottom: 1px solid #5f5f5f;
  padding-bottom: 2px; }

.ReactTable .rt-tbody .rt-tr .rt-td .search-icon {
  color: #5f5f5f; }

.ReactTable .rt-tr.-odd {
  background-color: #ffffff;
  align-items: center; }

.ReactTable .rt-tr.-even {
  background-color: #ffffff;
  background-color: #f7f9fc;
  align-items: center; }

.datatable-pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: #a0a8b1;
  background-color: white;
  border-radius: 0rem 0rem 1.5rem 1.5rem;
  font-size: 1.2rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1); }

.datatable-pagination li {
  flex: 1;
  text-align: center; }

.datatable-pagination li button {
  width: 100%;
  font-size: 13px;
  padding: 10px;
  border: none;
  color: #a0a8b1;
  background-color: white;
  border-radius: 0rem 0rem 1.5rem 1.5rem; }

.filter {
  background-color: white; }

.filter_container {
  display: flex;
  margin: 0 auto; }

.filter_container .w-large {
  width: 14rem !important; }

.filter_container .button {
  margin-right: 3rem;
  font-weight: 600;
  font-size: 1.4rem;
  cursor: pointer;
  width: 10rem;
  text-align: center;
  padding: 1rem 0rem; }

.filter_container_active-button {
  color: #4472c4;
  border-bottom: 3px solid #4472c4; }

.filter_container_normal-button {
  color: #717171; }

.generic_button_box {
  margin: 1rem 0rem;
  font-family: 'Heebo';
  cursor: pointer; }

.generic_button_box .generic_button_active {
  color: #4687f4;
  border: 1px solid #4687f4;
  text-align: center;
  font-size: 1.3rem;
  border-radius: 4rem;
  padding: 0.5rem 0rem;
  background-color: #f4f7fb;
  margin: 0.5rem 1rem 0.5rem 0rem; }

.generic_button_box .generic_button {
  border: 1px solid #adadad;
  text-align: center;
  font-size: 1.3rem;
  padding: 0.5rem 0rem;
  margin: 0.5rem 1rem 0.5rem 0rem;
  color: #adadad;
  border-radius: 4rem; }

.header-wrapper_container {
  justify-content: center;
  align-items: center; }

.header-wrapper_container input {
  width: 20%;
  border: 1px solid #bcbcbc;
  border-radius: 2rem;
  outline: none;
  padding: 0.5rem 1rem;
  display: inline-block;
  margin-top: 0px; }

.header-wrapper_container input[type='text']::-webkit-input-placeholder {
  color: #bcbcbc;
  font-size: 12px; }

.header-wrapper_container .search-form {
  text-align: right; }

.header-wrapper_container .search-form select {
  border: 1px solid #bcbcbc;
  width: 15%;
  border-radius: 2rem;
  outline: none;
  padding: 0.5rem 1rem; }

.header-wrapper_container .search-form_submit {
  border-radius: 2rem;
  border: none;
  background-color: #4687f4;
  padding: 0.4rem 1rem;
  width: 4rem;
  margin-left: -4rem; }

.header-wrapper_container .search-form_submit img {
  height: 1.2rem; }

.header-wrapper_container .logo_image {
  height: 2.8rem;
  cursor: pointer; }

.header-wrapper_container .user_image {
  height: 1.8rem;
  cursor: pointer; }

.header-wrapper_container .logout {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.header-wrapper_container .logout-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 110px;
  text-align: center;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 4px;
  border-radius: 5px;
  z-index: 1;
  margin-top: 5px; }

.header-wrapper_container .logout-hide {
  display: none; }

.header-wrapper_container .logout-show {
  display: block; }

.m_header-auto {
  margin: 35px auto; }

.dropdown {
  width: 100%;
  border-radius: 5px;
  padding: 0.7rem; }

select {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c1c1c1;
  color: #7a7a7a;
  margin-right: 10px;
  font-size: 12px;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 14px 10px;
  outline: none;
  margin-top: 5px; }

select:disabled {
  cursor: not-allowed; }

select option:first-child {
  font-weight: 600;
  font-size: 12px; }

select option {
  margin: 40px;
  font-size: 12px;
  color: black;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4); }

input {
  border: 1px solid #c1c1c1;
  width: 100%;
  padding: 14px 10px;
  border-radius: 10px;
  display: block;
  outline: none;
  outline: none;
  font-size: 12px;
  margin-top: 5px; }

input:disabled {
  cursor: not-allowed; }

input:focus {
  outline: none;
  border: 1px solid #c1c1c1; }

input::placeholder {
  color: #7a7a7a;
  font-size: 12px; }

.ReactModal__Overlay {
  z-index: 1010;
  background: rgba(0, 0, 0, 0.25) !important; }

.ReactModal__Content {
  position: absolute;
  width: 50vw;
  margin: 0px auto;
  max-height: 90vh;
  overflow-y: auto;
  inset: 75px !important;
  border-radius: 10px !important;
  padding: 15px;
  outline: none; }

.ReactModal__Content h3 {
  color: #058373;
  font-weight: bold;
  font-size: 2rem;
  border-bottom: 2px solid #ecf3fe;
  padding-bottom: 1.5rem; }

.loader-modal-overlay,
.content-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9900; }

.loader-modal-overlay {
  background-color: rgba(255, 255, 255, 0.3); }

.loader-modal-content,
.content-modal-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.loader-modal-content {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center; }

.cross-icon {
  float: right;
  color: #c7c7c7;
  border-radius: 50%;
  background-color: #fafafa;
  cursor: pointer; }

.red-flag__wrapper {
  border-radius: 10px; }

.red-flag__wrapper .heading {
  font-size: 22px;
  color: #00cdbe;
  padding-bottom: 1rem; }

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none; }

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  top: 3px !important; }

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%; }

.react-datepicker-popper {
  transform: scale(1.5) !important;
  top: 90px !important;
  left: 35px !important;
  z-index: 1000 !important; }

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 0 !important; }

.datepicker-box {
  position: relative; }

.datepicker-box.datepicker-disabled label {
  cursor: not-allowed; }

.date-field {
  border: 1px solid #c1c1c1;
  width: 100%;
  padding: 14px 10px;
  border-radius: 10px;
  display: block;
  outline: none;
  outline: none;
  font-size: 12px; }

.date-field::placeholder {
  color: #7a7a7a;
  font-size: 12px; }

.checkbox-box {
  display: flex;
  align-items: center; }

.checkbox-box input[type='checkbox'] {
  display: none; }

.checkbox-box input[type='checkbox'] + .checkbox .checkbox-checked {
  display: none; }

.checkbox-box input[type='checkbox'] + .checkbox .checkbox-unchecked {
  display: block; }

.checkbox-box input[type='checkbox']:checked + .checkbox .checkbox-checked {
  display: block; }

.checkbox-box input[type='checkbox']:checked + .checkbox .checkbox-unchecked {
  display: none; }

.checkbox-box .checkbox {
  margin: 0 5px 0 0; }

.checkbox-box .checkbox .checkbox-checked,
.checkbox-box .checkbox .checkbox-unchecked {
  cursor: pointer; }

.checkbox-box label {
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0; }

.modal-wrapper .comments {
  height: 6rem;
  border: 1px solid #ababab;
  border-radius: 1rem;
  outline: none;
  padding: 1rem; }

.modal-wrapper .upload-file {
  display: inline-block !important;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 3rem;
  height: 1rem; }

.modal-wrapper .file {
  height: 600px; }

.modal-wrapper .radio_button-label {
  margin: 5px 0px;
  display: flex;
  font-size: 1.2rem;
  align-items: center; }

.modal-wrapper .radio_button-label .radio_button {
  width: 10%;
  height: 1.5rem;
  display: inline-block;
  margin-top: 0px; }

.modal-wrapper .radio_button-label img {
  height: 1.5rem; }

.modal-wrapper .text-centre {
  text-align: center; }

.modal-wrapper .card_heading {
  color: #058373;
  font-weight: bold;
  margin: 0rem 0.5rem;
  font-size: 2rem;
  border-bottom: 2px solid #ecf3fe;
  padding-bottom: 1rem; }

.modal-wrapper .card_heading .icon {
  height: 2.5rem;
  cursor: pointer; }

.modal-wrapper .input_heading {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem 0.5rem; }

.modal-wrapper .save_button {
  margin-top: 1rem;
  text-align: center; }

.modal-wrapper .save_button .button-width {
  width: max-content !important;
  cursor: pointer; }

.modal-wrapper .save_button .basic-button {
  margin-top: 1rem;
  text-align: center;
  border-radius: 5px;
  padding: 1rem 0rem;
  width: 12rem; }

.modal-wrapper .save_button .button-success {
  color: #ffffff;
  background-color: #4687f4;
  border: none;
  padding: 1rem 2.2rem;
  border-radius: 0.5rem; }

.modal-wrapper .save_button .button-success:disabled {
  cursor: not-allowed; }

.modal-wrapper .save_button .button-failure {
  color: #bfbfbf;
  background-color: white;
  border: 1px solid #bfbfbf;
  margin-right: 2rem; }

.modal-wrapper .card_heading_sub {
  color: #058373;
  font-weight: bold;
  font-family: 'Heebo';
  margin: 2.2rem 0.5px 0.5px 0.5px;
  font-size: 1.6rem; }

.modal-wrapper .toggle_button_box {
  padding: 1rem 0.5rem;
  font-family: 'Heebo';
  cursor: pointer; }

.modal-wrapper .toggle_button_box .side_title {
  font-size: 1.4rem; }

.modal-wrapper .toggle_button_box .toggle_button_active {
  color: #4687f4;
  border: 1px solid #4687f4;
  text-align: center;
  font-size: 1.2rem;
  border-radius: 4rem;
  padding: 0.5rem 0rem;
  background-color: #f4f7fb;
  margin: 0.5px 1.5rem 0.5px 0rem; }

.modal-wrapper .toggle_button_box .toggle_button {
  text-align: center;
  font-size: 1.2rem;
  padding: 5px 0px;
  margin: 0.5px 1.5rem 0.5px 0rem;
  color: #5e5e5e; }

.cursor-pointer {
  cursor: pointer; }

.right-aligned {
  text-align: right; }

.sort_arrow {
  height: 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer; }

.invoice-pointer {
  color: black;
  transition: color 0.3s; }

.invoice-pointer:hover {
  color: #0052cc;
  /* Blue color on hover */
  text-decoration: underline;
  cursor: 'pointer'; }

.no-data-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20rem; }

.no-data-found .icon {
  margin-bottom: 2px; }

.no-data-found .primary-message {
  color: #888888;
  margin-bottom: 2px;
  font-size: 1.8rem;
  font-weight: 400; }

.no-data-found .secondary-message {
  color: #888888;
  font-size: 1.4rem;
  font-weight: 300; }

.dashboard-wrapper .progress {
  height: 0.5rem;
  margin-top: 0.5rem; }

.dashboard-wrapper .gst-text {
  color: #4687f4;
  font-size: 11px; }

.dashboard-wrapper .button_common {
  border-radius: 0px 9px 9px 0px;
  outline: none;
  padding: 8px 5px 8px 5px;
  width: 8rem;
  text-align: center;
  border: none;
  border-style: none;
  border-left-width: 3px;
  border-left-style: solid; }

.dashboard-wrapper .buttons-box {
  display: inline-block; }

.dashboard-wrapper .buttons-box .toggle-buttons {
  text-align: center;
  display: flex;
  padding: 1rem 0rem 0rem;
  cursor: pointer; }

.dashboard-wrapper .buttons-box .toggle-buttons .button-left {
  color: #7a7a7a;
  border: 1px solid #bcbcbc;
  padding: 0.4rem 1rem;
  border-radius: 2rem 0rem 0rem 2rem;
  background-color: white;
  font-size: 1.2rem;
  min-width: 12rem; }

.dashboard-wrapper .buttons-box .toggle-buttons .button-right {
  border-radius: 0rem 2rem 2rem 0rem; }

.dashboard-wrapper .buttons-box .toggle-buttons .button-center {
  border-radius: 0; }

.dashboard-wrapper .buttons-box .toggle-buttons .active-button {
  color: #4687f4;
  border: 1px solid #4687f4;
  background-color: #f4f7fb; }

.tracker_wrapper .main_tracker {
  position: relative;
  margin: 2rem 2.5rem; }

.tracker_wrapper .main_tracker_box {
  display: flex;
  justify-content: space-evenly; }

.tracker_wrapper .main_tracker_box img {
  border-radius: 50%;
  border-radius: 50%;
  padding: 0.6rem;
  height: 5rem;
  width: 5rem;
  margin-right: 1rem;
  z-index: 20; }

.tracker_wrapper .main_tracker_box .complete {
  background-color: #1de9b6; }

.tracker_wrapper .main_tracker_box .on_going {
  background-color: #4687f4;
  font-weight: 600 !important; }

.tracker_wrapper .main_tracker_box .pending {
  background-color: #ededed; }

.tracker_wrapper .main_tracker_box .by_passed {
  background-color: #ffc107; }

.tracker_wrapper .main_tracker_box::before {
  height: 6px;
  width: 90%;
  content: '';
  position: absolute;
  top: 25px;
  border-radius: 2rem;
  background-color: #ededed; }

.tracker_wrapper .sub_tracker {
  display: flex;
  align-items: baseline;
  justify-content: space-evenly; }

.tracker_wrapper .sub_tracker_section {
  border: 1px solid #e9e9e9;
  border-radius: 1rem;
  width: 30rem; }

.tracker_wrapper .sub_tracker_section-top {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e9e9e9;
  align-items: center; }

.tracker_wrapper .sub_tracker_section-top .heading {
  font-size: 1.2rem;
  font-weight: 600; }

.tracker_wrapper .sub_tracker_section-top .time {
  color: #5e5e5e;
  font-size: 1rem; }

.tracker_wrapper .sub_tracker_section-bottom {
  position: relative; }

.tracker_wrapper .sub_tracker_section-bottom-inner {
  padding: 1rem 0rem;
  margin: 1.5rem 0rem; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_time {
  color: #5e5e5e;
  font-size: 1rem; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles {
  display: flex;
  justify-content: space-around;
  align-items: center; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles_figure {
  z-index: 20; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles_figure img {
  border-radius: 50%;
  border-radius: 50%;
  padding: 0.6rem;
  height: 3rem;
  width: 3rem;
  margin-right: 1rem; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles_figure .complete {
  background-color: #1de9b6; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles_figure .on_going {
  background-color: #4687f4;
  font-weight: 600 !important; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles_figure .pending {
  background-color: #ededed; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles_figure .by_passed {
  background-color: #ffc107; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles_content {
  color: #484848;
  font-weight: 300;
  font-size: 1.4rem; }

.tracker_wrapper .sub_tracker_section-bottom-inner .box_circles .on_going {
  font-weight: 600; }

.tracker_wrapper .sub_tracker_section-bottom-inner::before {
  width: 2px;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 34px;
  background-color: #ededed; }

.login-wrapper {
  overflow: hidden; }

.login-wrapper .nav-bar {
  margin: 35px auto; }

.login-wrapper .nav-bar img {
  height: 25px;
  cursor: pointer; }

.login-wrapper .login-container {
  margin-top: 85px; }

.login-wrapper .login-container_left {
  display: flex; }

.login-wrapper .login-container_left .login {
  margin: 40px 0px; }

.login-wrapper .login-container_left h2 {
  color: #001061;
  font-weight: 600;
  font-size: 2.5rem; }

.login-wrapper .login-container_left .form-input {
  margin: 40px 0px; }

.login-wrapper .login-container_left .form-input input {
  border: none;
  width: 35rem;
  height: 5rem;
  border-radius: 5px;
  display: block;
  outline: none;
  font-size: 1.2rem;
  margin-bottom: 2rem; }

.login-wrapper .login-container_left .form-input input::placeholder {
  color: #bcbcbc;
  font-size: 1rem; }

.login-wrapper .login-container_left button {
  display: block;
  border: none;
  color: white;
  background-color: #4687f4;
  border-radius: 5px;
  margin-top: 5rem;
  width: 35rem;
  height: 5rem;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0px 16px 33px 5px rgba(70, 135, 244, 0.27); }

.login-wrapper .login-container_right {
  display: inline-block;
  position: absolute;
  right: 0; }

.login-wrapper .login-container_right img {
  height: 55rem; }

.interest_charges_wrapper .wavier_img {
  height: 2.2rem; }

.interest_charges_wrapper .chip {
  border-radius: 6rem;
  padding: 5px 18px;
  font-weight: 500;
  padding-top: 6px; }

.interest_charges_wrapper .SMA-0,
.interest_charges_wrapper .SMA-1,
.interest_charges_wrapper .SMA-2,
.interest_charges_wrapper .NPA {
  color: #e34234;
  background-color: #f7cfcc;
  display: flex;
  align-items: center; }

.interest_charges_wrapper .SMA-0::before,
.interest_charges_wrapper .SMA-1::before,
.interest_charges_wrapper .SMA-2::before,
.interest_charges_wrapper .NPA::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px; }

.interest_charges_wrapper .SMA-0 {
  color: #e34234;
  background-color: #f7cfcc; }

.interest_charges_wrapper .SMA-0::before {
  background-color: #e34234; }

.interest_charges_wrapper .SMA-1 {
  color: #ef5350;
  background-color: #ffcdd2; }

.interest_charges_wrapper .SMA-1::before {
  background-color: #ef5350; }

.interest_charges_wrapper .SMA-2 {
  color: #b31b1b;
  background-color: #ffacac; }

.interest_charges_wrapper .SMA-2::before {
  background-color: #b31b1b; }

.interest_charges_wrapper .NPA {
  color: #ca4949;
  background-color: #faefef; }

.interest_charges_wrapper .NPA::before {
  background-color: #ca4949; }

.interest_charges_wrapper .Dpd {
  color: #ca4949;
  background-color: #ffe7e7; }

.interest_charges_wrapper .Blocked,
.interest_charges_wrapper .Closed {
  color: #ca4949;
  background-color: #faefef; }

.interest_charges_wrapper .Blocked::before,
.interest_charges_wrapper .Closed::before {
  background-color: #ca4949; }

.interest_charges_wrapper .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px; }

.interest_charges_wrapper .Blocked .dot,
.interest_charges_wrapper .Closed .dot {
  background-color: #ca4949; }

.interest_charges_wrapper .Active {
  color: #39b73f;
  background-color: #f4fbf4; }

.interest_charges_wrapper .Active .dot {
  background-color: #39b73f; }

.interest_charges_wrapper .card_secondary {
  font-family: 'Heebo';
  font-weight: bold;
  padding: 1rem;
  border-radius: 1rem;
  font-size: 1.2rem; }

.interest_charges_wrapper .card_secondary_orange {
  background-color: #fff4eb;
  border: 1px solid #fe891d;
  color: #fe891d;
  margin: 2rem 0rem 1rem 0rem; }

.interest_charges_wrapper .card_secondary_green {
  background-color: #ecfefc;
  border: 1px solid #057365;
  color: #057365;
  margin: 0rem; }

.interest_charges_wrapper .card_secondary_value {
  color: black;
  font-size: 2rem; }

.interest_charges_wrapper .card_heading_table {
  border-bottom: 2px solid #ecf3fe; }

.interest_charges_wrapper h4 {
  color: #058373;
  font-size: 2rem;
  font-weight: bold;
  margin: 0rem 0.5rem; }

.interest_charges_wrapper .ReactTable .rt-tr.-odd,
.interest_charges_wrapper .ReactTable .rt-tr.-even {
  padding: 5px 15px; }

.interest_charges_wrapper .ReactTable .rt-tr .rt-td .invoice_number {
  font-size: 1.3rem;
  color: #4687f4; }

.interest_charges_wrapper .ReactTable .rt-tr .rt-td .invoice_number:hover {
  text-decoration: underline; }

.interest_charges_wrapper .icon_image {
  height: 2rem;
  margin-left: 1rem;
  cursor: pointer; }

.interest_charges_wrapper .repayment_table_wrapper .ReactTable {
  background-color: #e9faf6; }

.interest_charges_wrapper .repayment_table_wrapper .ReactTable .rt-thead.-header {
  display: none; }

.interest_charges_wrapper .repayment_table_wrapper .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid white !important; }

.interest_charges_wrapper .repayment_table_wrapper .ReactTable .rt-tr.-odd,
.interest_charges_wrapper .repayment_table_wrapper .ReactTable .rt-tr.-even {
  background-color: #e9faf6;
  padding: 0px 15px; }

.interest_charges_wrapper .repayment_table_wrapper .invoice_capsule {
  color: #034e45;
  background-color: #a8ebdb;
  border-radius: 10px;
  text-align: center;
  margin: 5px 0px; }

.manage-charges__modal {
  margin-top: 20px; }

.manage-charges__modal > * {
  margin-top: 40px; }

.manage-charges__modal .user-name-tag {
  background: #00cdbe;
  color: #fff;
  border-radius: 1000px;
  width: 40px;
  height: 40px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center; }

.manage-charges__modal .label {
  color: #adadad;
  font-size: 1.2rem; }

.manage-charges__modal .value {
  font-size: 1.6rem; }

.manage-charges__modal .charges-heading {
  font-size: 1.2rem; }

.manage-charges__modal .capsule {
  border-radius: 1rem;
  padding: 0.2rem 1rem; }

.manage-charges__modal .capsule-blue {
  background-color: #ecf3fe;
  color: #4687f4; }

.manage-charges__modal .capsule-green {
  background-color: #effaf0;
  color: #39b73f; }

.offer_wrapper .submit_button {
  background-color: #4687f4;
  border-radius: 5px;
  font-size: 1.4rem;
  border: none;
  padding: 1rem 3rem;
  color: white;
  margin: 4rem auto; }

.offer_wrapper .border-grey {
  border-bottom: 1px solid #ecf1f9; }

.offer_wrapper .main-row {
  padding: 1rem 0rem; }

.offer_wrapper .main-row_inner {
  color: #adadad;
  font-family: 'Heebo';
  font-size: 1.4rem;
  margin: 1rem 0rem; }

.offer_wrapper .unlock_box {
  text-align: center;
  margin: 2rem; }

.offer_wrapper .limit_button {
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding: 1rem; }

.offer_wrapper .credit-button {
  justify-content: flex-end; }

.offer_wrapper .credit-button .btn {
  color: #058373;
  background-color: white;
  border: 1px solid #058373; }

.offer_wrapper .hold-button {
  background-color: rgba(254, 137, 29, 0.1);
  color: #fe891d;
  margin-right: 2rem; }

.offer_wrapper .hold-button img {
  height: 2rem;
  margin-right: 1rem; }

.offer_wrapper .re_active {
  background: #4687f4;
  color: white; }

.offer_wrapper .block-button {
  background-color: rgba(50, 50, 50, 0.1);
  color: #323232;
  margin-right: 2rem; }

.offer_wrapper .block-button img {
  height: 1.6rem;
  margin-right: 1rem; }

.offer_wrapper .revise_button {
  background: #058373;
  color: white; }

.offer_wrapper .close_button {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center; }

.offer_wrapper .close_button_inner {
  border-radius: 5px;
  background: rgba(255, 42, 42, 0.81);
  padding: 1.2rem 6rem;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 3rem 0rem; }

.offer_wrapper .limit-parameters {
  font-weight: 400;
  color: #058373;
  font-family: 'Heebo';
  margin: 4px 0px 0px 2px;
  font-size: 12px; }

.offer_wrapper .add_button {
  color: #4687f4;
  border: 2px solid #4687f4;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 2rem 0rem 0rem 0rem;
  max-width: 15%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; }

.offer_wrapper .add_button img {
  height: 1.8rem;
  margin-right: 1rem; }

.offer_wrapper .offer-history {
  text-align: center;
  width: 50%; }

.offer_wrapper .offer-history_active {
  background-color: #daf1db;
  color: #388e3c;
  border-radius: 2rem;
  padding: 1rem;
  padding: 0.5rem 0rem; }

.offer_wrapper .offer-history_expired {
  background-color: #f3d7d7;
  color: #ca4949;
  border-radius: 2rem;
  padding: 0.5rem 0rem; }

.offer_wrapper .modal_card {
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.16);
  padding-bottom: 2rem;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  margin: 1rem 2rem;
  border: none; }

.offer_wrapper .modal_card-heading {
  color: #058373;
  font-weight: bold;
  margin: 0rem;
  padding-top: 2rem; }

.offer_wrapper .capsule {
  border-radius: 1rem;
  padding: 0.2rem 1.5rem;
  cursor: pointer;
  border-radius: 99px; }

.offer_wrapper .capsule-red {
  background-color: #f8e7e7;
  color: #ca4949; }

.offer_wrapper .capsule-green {
  background-color: #effaf0;
  color: #39b73f; }

.offer_wrapper .capsule-blue {
  background-color: #ecf1f9;
  color: #4687f4; }

.offer_wrapper .modal_title {
  color: #058373;
  font-weight: bold;
  font-size: 16px;
  padding: 0rem 2.2rem;
  margin: 0px; }

.leadmanager-navs-wrapper .nav-top {
  background-color: white;
  padding: 5px 0px;
  font-size: 13px;
  color: #474747;
  margin: 0px 18px;
  display: flex;
  align-items: center;
  font-family: Heebo; }

.leadmanager-navs-wrapper .nav-top.nav-link {
  color: black; }

.leadmanager-navs-wrapper .nav-top .logo {
  color: #4687f4;
  padding: 0px 5px; }

.leadmanager-navs-wrapper .nav-top .value {
  color: #058373;
  padding: 0px 5px; }

.leadmanager-navs-wrapper .navbar {
  background-color: white;
  border: none;
  margin-bottom: 0;
  border-radius: 0;
  padding: 0rem;
  border-radius: 5px; }

.leadmanager-navs-wrapper .navbar.navbar-fixed-top {
  z-index: 1001; }

.leadmanager-navs-wrapper .navbar .credit-navs {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row; }

.leadmanager-navs-wrapper .navbar .credit-navs a {
  width: 170px;
  text-align: center;
  margin: 0px 5px;
  color: #717171;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0px 5px;
  border-bottom: none;
  padding: 10px 0px;
  display: inline-block; }

.leadmanager-navs-wrapper .navbar .credit-navs a.active {
  border-bottom: 3px solid #4472c4;
  color: #4472c4;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0px 5px;
  padding: 10px 0px;
  display: inline-block; }

.leadmanager-navs-wrapper .navbar .credit-navs .w-200 {
  width: 200px; }

.compare-details-wrapper .border-bottom {
  border-bottom: 2px solid #ecf3fe; }

.compare-details-wrapper .image-box {
  margin-left: 5px; }

.compare-details-wrapper input[type='file'] {
  display: none; }

.compare-details-wrapper .map-container .map-heading {
  font-weight: bolder;
  color: black;
  font-family: 'Heebo';
  font-size: medium;
  margin-top: 0.5rem; }

.compare-details-wrapper .btn {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; }

.compare-details-wrapper .btn_verify {
  width: 20rem;
  font-size: 1.5rem;
  background-color: #8bd591;
  margin-right: 2rem; }

.compare-details-wrapper .btn_cancel {
  width: 20rem;
  font-size: 1.5rem;
  background-color: #fe5858; }

.compare-details-wrapper .file_input {
  border: 2px dashed #4687f4;
  color: #bcbcbc;
  border-radius: 10px;
  padding: 9px 10px;
  font-weight: 400;
  margin-top: 0.8rem;
  display: block; }

.compare-details-wrapper .icon_img {
  margin-right: 10px; }

.compare-details-wrapper .downArrow {
  height: 2.5rem;
  margin-left: 1rem;
  cursor: pointer; }

.compare-details-wrapper .preview_img {
  width: 80rem; }

.compare-details-wrapper .profile_img {
  height: 45rem;
  display: flex;
  border-radius: 1rem; }

.compare-details-wrapper .promoter_img {
  height: 45rem;
  display: flex;
  margin: 15px auto;
  border-radius: 1rem; }

.compare-details-wrapper .card_heading {
  color: #058373;
  font-weight: bold;
  font-family: 'Poppins';
  margin: 0px 5px;
  font-size: 2rem;
  border-bottom: 2px solid #ecf3fe;
  padding: 0px 0px 5px 0px; }

.compare-details-wrapper .card_heading .button {
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 1px solid #058373;
  color: #058373;
  background-color: transparent;
  transition: transform 0.3s ease;
  font-size: 1.6rem; }

.compare-details-wrapper .card_heading .button.clicked {
  background-color: #b7dcd3;
  transform: translateY(4px); }

.compare-details-wrapper .card_heading .rotate-90 {
  transform: rotate(180deg); }

.compare-details-wrapper .card_heading .invoice_input {
  width: 18rem; }

.compare-details-wrapper .card_heading_sub {
  color: #058373;
  font-weight: bold;
  font-family: 'Heebo';
  margin: 22px 5px 5px 5px;
  font-size: 1.6rem; }

.compare-details-wrapper .small-card {
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.16);
  padding-bottom: 2rem;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  margin: 0rem 2rem;
  border: none; }

.compare-details-wrapper .comments_text-area {
  height: 140px;
  font-size: 12px;
  padding: 14px 10px;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  margin-top: 0.8rem;
  font-weight: 500; }

.compare-details-wrapper .comments_text-area:focus {
  border: 1px solid #c1c1c1;
  outline: none; }

.compare-details-wrapper [contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: #7a7a7a;
  background-color: transparent; }

.compare-details-wrapper .heading-blue {
  font-family: 'Poppins';
  color: #4687f4;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px; }

.compare-details-wrapper .value {
  font-family: 'Heebo';
  font-size: 12px; }

.compare-details-wrapper .heading {
  font-weight: bolder;
  font-size: 13px; }

.compare-details-wrapper h4 {
  color: #4687f4;
  font-family: 'Heebo';
  margin: 1rem 0rem; }

.compare-details-wrapper .human_img {
  height: 120px;
  border-radius: 10px;
  width: 130px;
  margin: 10px; }

.compare-details-wrapper .kyc_img {
  height: 45rem; }

.compare-details-wrapper .input_heading {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 8px 5px; }

.compare-details-wrapper .upload_file {
  display: flex; }

.compare-details-wrapper .upload_file img {
  height: 24px; }

.compare-details-wrapper .upload_file span {
  color: #1d1d1d;
  background-color: #d4e3fc;
  border-radius: 34px;
  padding: 5px 15px;
  max-width: 40rem;
  min-width: 10rem;
  display: inline-block; }

.compare-details-wrapper .carousel .carousel-control-prev,
.compare-details-wrapper .carousel .carousel-control-next {
  width: 4%; }

.compare-details-wrapper .carousel .carousel-control-prev .carousel-control-prev-icon,
.compare-details-wrapper .carousel .carousel-control-prev .carousel-control-next-icon,
.compare-details-wrapper .carousel .carousel-control-next .carousel-control-prev-icon,
.compare-details-wrapper .carousel .carousel-control-next .carousel-control-next-icon {
  width: 34px;
  background-color: #a4a4a4;
  border-radius: 50%;
  padding: 1.5rem 0.5rem; }

.compare-details-wrapper .carousel .carousel_item {
  margin-top: 4rem;
  position: relative; }

.compare-details-wrapper .carousel .carousel_item img {
  margin: 0 auto;
  border-radius: 1rem;
  height: 48rem; }

.compare-details-wrapper .carousel .carousel_item .carousel-caption {
  position: initial;
  width: 30rem; }

.compare-details-wrapper .carousel .carousel_item .carousel-caption h3 {
  top: -4%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  color: black;
  font-weight: bold;
  font-size: 1.5rem;
  border-bottom: none;
  text-align: left;
  margin: 0px;
  padding: 0px; }

.compare-details-wrapper .carousel .carousel_item .carousel-caption p {
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  bottom: -4%;
  width: 50rem;
  color: black; }

.compare-details-wrapper .carousel .carousel_item .carousel-caption .rotate-icon {
  color: #4687f4;
  cursor: pointer; }

.compare-details-wrapper .carousel .carousel_item .location_icon {
  height: 2rem;
  width: 2rem; }

.compare-details-wrapper .location_map {
  width: 93% !important;
  height: 90% !important;
  margin-top: 0.5rem; }

.compare-details-wrapper .invoice_img {
  height: 50rem; }

.entity-kyc-wrapper table {
  font-family: 'Heebo'; }

.entity-kyc-wrapper table th {
  padding: 1.5rem; }

.entity-kyc-wrapper table td {
  padding: 1.5rem;
  color: #474747; }

.entity-kyc-wrapper table td .doc-name {
  border: 1px solid #bfbfbf;
  padding: 0.6rem;
  border-radius: 5px; }

.entity-kyc-wrapper table thead {
  padding: 1.5rem;
  color: #adadad;
  box-shadow: 0rem 0.5rem 2rem rgba(156, 189, 243, 0.13);
  border-radius: 2rem;
  border-bottom: 1px solid #bfbfbf; }

.entity-kyc-wrapper table tbody tr:nth-child(even) {
  background: #f7f9fc; }

.entity-kyc-wrapper .span {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem; }

.entity-kyc-wrapper .card_heading {
  color: #058373;
  font-weight: bold;
  font-family: 'Poppins';
  margin: 0px 2rem;
  font-size: 2rem;
  border-bottom: 2px solid #ecf3fe;
  padding: 0px 0px 5px 0px; }

.entity-kyc-wrapper .width {
  width: 90%; }

.entity-kyc-wrapper .cursor {
  cursor: pointer; }

.entity-kyc-wrapper .cross {
  height: 2rem; }

.entity-kyc-wrapper .icon-osv {
  display: flex; }

.entity-kyc-wrapper .icon-osv img {
  height: 2rem;
  margin: 0.3rem 1rem; }

.entity-kyc-wrapper .icon-osv h2 {
  color: #1d1d1d;
  padding-top: 0.2rem; }

.digilocker-document-wrapper {
  margin-top: 5rem;
  height: 100%;
  justify-content: space-between; }

.digilocker-document-wrapper .image-box {
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.digilocker-document-wrapper .image-box .rotate-icon {
  color: #4687f4;
  cursor: pointer; }

.digilocker-document-wrapper .img {
  height: auto;
  width: 20rem; }

.digilocker-document-wrapper .aadhar-verification {
  display: flex;
  width: 100%;
  height: 8rem;
  justify-content: center; }

.digilocker-document-wrapper .preview_file {
  display: flex;
  justify-content: center;
  align-items: flex-start; }

.digilocker-document-wrapper .preview_file .rotate-icon {
  color: #4687f4;
  cursor: pointer; }

.tab-wrapper {
  background-color: #8787872e;
  width: 80%; }

.tab-wrapper .tab-container {
  display: flex; }

.tab-wrapper .tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer; }

.tab-wrapper .tab.selected {
  font-weight: bold;
  color: #058373;
  border-bottom: 3px solid #058373; }

.tab-wrapper .underline {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 2px;
  background-color: black;
  transition: left 0.3s ease-in-out; }

.payment_wrapper .add_button {
  color: #4687f4;
  border: 2px solid #4687f4;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 2rem 0rem 0rem 0rem;
  max-width: 15%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; }

.payment_wrapper .add_button img {
  height: 18px;
  margin-right: 10px; }

.payment_wrapper .details_button {
  color: #4687f4;
  background-color: rgba(70, 135, 244, 0.1);
  border-radius: 5px;
  padding: 5px 10px;
  width: 7rem;
  text-align: center;
  border: none; }

.payment_wrapper .capsule {
  border-radius: 1rem;
  padding: 2px 10px;
  font-size: 1.1rem;
  text-align: center;
  min-width: 10rem;
  display: inline-block; }

.payment_wrapper .capsule_orange {
  color: #ff7d1a;
  background-color: #fff7eb; }

.payment_wrapper .capsule_green {
  color: #1de9b6;
  background-color: #e3fcf6; }

.payment_wrapper .capsule_red {
  color: #ff6055;
  background-color: #ffefee; }

.credit-behaviour-wrapper {
  margin-top: 30px; }

.credit-behaviour-wrapper h4 {
  font-size: 18px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #058373;
  margin-top: 0; }

.credit-behaviour-wrapper .submit_button {
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: white;
  border: 2px solid #4687f4;
  background-color: #4687f4; }

.credit-behaviour-wrapper .btns-box button {
  width: 100%;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.credit-behaviour-wrapper .btns-box button.add-btn {
  background-color: #058373; }

.credit-behaviour-wrapper .btns-box button.edit-btn {
  background-color: #29a9e0; }

.credit-behaviour-wrapper .btns-box button.reset-btn {
  background-color: #ff0000; }

.credit-behaviour-wrapper .btns-box button div {
  margin-right: 5px; }

.credit-behaviour-wrapper .nav-tabs {
  margin: 30px 30px 0; }

.credit-behaviour-wrapper .nav-tabs > a {
  margin: 0 11px 0px;
  border-radius: 5px 5px 0 0;
  position: relative;
  color: #1d1d1d;
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: 0;
  padding: 10px 30px;
  font-weight: 600;
  background-color: #e5e5e5; }

.credit-behaviour-wrapper .nav-tabs > a.active::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #058373;
  position: absolute;
  bottom: 0;
  left: 1%; }

.credit-behaviour-wrapper .tab-content {
  border-right: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  border-left: 1px solid #bcbcbc;
  border-top: 2px solid #e5e5e5;
  border-radius: 25px;
  margin-top: -2px;
  margin-bottom: 15px;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.16);
  padding: 20px; }

.credit-behaviour-wrapper .tab-content .box-content {
  background-color: white;
  border-radius: 12px; }

.credit-behaviour-wrapper .tab-content .box-content .box-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16); }

.credit-behaviour-wrapper .tab-content .box-content .box-header .box-heading {
  margin-top: 0;
  font-size: 16px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #058373; }

.credit-behaviour-wrapper .tab-content .box-content .box-header .edit-icon {
  color: #5f5f5f;
  border-bottom: 1px solid #5f5f5f;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 3px; }

.credit-behaviour-wrapper .tab-content .box-content .bank-details-list {
  list-style: none;
  margin: 10px 0; }

.credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li {
  display: flex;
  align-items: center;
  margin: 5px 0; }

.credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li label,
.credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li span {
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #1d1d1d;
  margin: 0;
  flex: 1; }

.credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li svg {
  color: #b22929; }

.credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li .months-list {
  flex: 1;
  display: flex;
  list-style: none;
  width: 100%; }

.credit-behaviour-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #ffffff;
  flex: 1;
  background-color: #8bd591;
  text-align: center;
  border-right: 0.5px solid #fff; }

.credit-behaviour-wrapper .box-wrapper {
  border-radius: 15px;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.16);
  background-color: white;
  margin-bottom: 35px;
  padding: 25px 20px; }

.credit-behaviour-wrapper .box-wrapper .heatMapArrow {
  text-align: center; }

.credit-behaviour-wrapper .box-wrapper .heatMapArrow .arrow {
  font-size: 40px; }

.credit-behaviour-wrapper .box-wrapper.secondary {
  padding: 0; }

.credit-behaviour-wrapper .box-wrapper.secondary .box-header {
  background-color: #ecf9f1;
  padding: 15px;
  border-radius: 15px 15px 0 0; }

.credit-behaviour-wrapper .box-wrapper.secondary .box-header h4 {
  margin-bottom: 0; }

.credit-behaviour-wrapper .box-wrapper.secondary .box-content ul {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0; }

.credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li {
  padding: 15px;
  border-right: 1px solid #eeeeee;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li label {
  font-size: 14px;
  line-height: 1.33;
  letter-spacing: 0;
  color: #1d1d1d;
  margin-bottom: 10px; }

.credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li span {
  font-size: 14px;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #1d1d1d;
  display: flex;
  align-items: center; }

.credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li span .icon {
  margin-top: -3px; }

.credit-behaviour-wrapper .box-wrapper.secondary .box-content ul li:last-child {
  border-right: none; }

.credit-behaviour-wrapper .box-wrapper .box-header {
  border-bottom: 1px solid #e0e0e0; }

.credit-behaviour-wrapper .box-wrapper .chart-details {
  margin-top: 20px; }

.credit-behaviour-wrapper .box-wrapper .chart-details span {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: #1d1d1d; }

.credit-behaviour-wrapper .box-wrapper .chart-box {
  margin-top: 25px;
  overflow: auto !important; }

.credit-behaviour-wrapper .box-wrapper .box-footer {
  margin: 20px -20px -25px -20px;
  background-color: #058373;
  border-radius: 0 0 15px 15px; }

.credit-behaviour-wrapper .box-wrapper .box-footer ul {
  display: flex;
  margin-bottom: 0; }

.credit-behaviour-wrapper .box-wrapper .box-footer ul li {
  flex: 1;
  padding: 10px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.credit-behaviour-wrapper .box-wrapper .box-footer ul li label {
  font-size: 14px;
  line-height: 1.33;
  letter-spacing: 0;
  color: #ffffff;
  margin-bottom: 10px; }

.credit-behaviour-wrapper .box-wrapper .box-footer ul li span {
  font-size: 24px;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  align-items: center; }

.credit-behaviour-wrapper .box-wrapper .box-footer ul li span .icon {
  margin-top: -3px; }

.credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child {
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 0 15px 15px; }

.credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
  border-bottom-left-radius: 15px; }

.credit-behaviour-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
  border-bottom-right-radius: 15px; }

.credit-behaviour-wrapper .box-wrapper .select-box {
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #1d1d1d;
  border: 1px solid #e0e0e0;
  padding: 3px; }

.credit-behaviour-wrapper .ReactTable {
  position: relative; }

.credit-behaviour-wrapper .ReactTable .rt-table {
  overflow-x: scroll; }

.credit-behaviour-wrapper .ReactTable .rt-table::-webkit-scrollbar {
  height: 3px; }

.credit-behaviour-wrapper .ReactTable .rt-table::-webkit-scrollbar-button {
  display: none; }

.credit-behaviour-wrapper .ReactTable .rt-table::-webkit-scrollbar-track {
  background: transparent; }

.credit-behaviour-wrapper .ReactTable .rt-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #001061; }

.credit-behaviour-wrapper .ReactTable .rt-noData {
  position: absolute;
  top: 70%;
  background: transparent !important;
  color: black !important; }

.credit-behaviour-wrapper .ReactTable.no-data.no-pagination .rt-noData {
  top: 70%; }

.credit-behaviour-wrapper .ReactTable.no-data .rt-tbody {
  min-height: 60px; }

.credit-behaviour-wrapper .datatable-pagination {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1); }

.credit-behaviour-wrapper .datatable-pagination li {
  flex: 1;
  text-align: center; }

.credit-behaviour-wrapper .datatable-pagination li button {
  width: 100%;
  padding: 10px;
  border: none; }

.credit-behaviour-wrapper .ReactTable {
  margin: 20px auto;
  border-radius: 0px; }

.credit-behaviour-wrapper .ReactTable .rt-table {
  border-radius: 0px; }

.credit-behaviour-wrapper .ReactTable .rt-th {
  border-right: 1px solid white !important; }

.credit-behaviour-wrapper .ReactTable .rt-thead.-header {
  border-radius: 0px;
  padding: 0px;
  margin-bottom: 0px; }

.credit-behaviour-wrapper .ReactTable .rt-thead .rt-tr {
  text-align: center !important; }

.credit-behaviour-wrapper .ReactTable .rt-thead .rt-th {
  position: relative;
  background-color: #058373;
  font-size: 12px;
  line-height: 1.25;
  letter-spacing: 0;
  color: #ffffff;
  padding: 12px 0;
  border-right-color: #fff;
  white-space: unset;
  text-align: center; }

.credit-behaviour-wrapper .ReactTable .rt-thead .rt-th div {
  margin: 0 auto; }

.credit-behaviour-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
  content: '\f0d7';
  \position: absolute;
  right: 9px;
  top: 10px;
  font-size: 16px; }

.credit-behaviour-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
  content: '\f0d8';
  position: absolute;
  right: 9px;
  top: 10px;
  font-size: 16px; }

.credit-behaviour-wrapper .ReactTable .rt-tbody {
  border-radius: 0px; }

.credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr {
  font-size: 13px;
  line-height: 1.25;
  letter-spacing: 0;
  text-align: center;
  color: #1d1d1d;
  padding: 16px 0 !important; }

.credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
  border-right-color: transparent; }

.credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
  color: #fff;
  border-radius: 25px;
  width: 23px;
  height: 24px;
  display: inline-block;
  text-align: center;
  padding-top: 2px; }

.credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
  background-color: #78e35f; }

.credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
  background-color: #ff0000; }

.credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
  background-color: #f7f9fc; }

.credit-behaviour-wrapper .ReactTable .rt-tbody .rt-tr.-even {
  background-color: white; }

.credit-behaviour-wrapper .applicants-table .rt-thead .rt-th {
  font-size: 12px;
  word-wrap: break-word; }

.credit-behaviour-wrapper .applicants-table .rt-thead .rt-th.-sort-asc::after, .credit-behaviour-wrapper .applicants-table .rt-thead .rt-th.-sort-desc::after {
  right: 5px;
  top: 20px; }

.credit-behaviour-wrapper .applicants-table .rt-tbody .rt-tr .rt-td {
  white-space: unset; }

.credit-behaviour-wrapper .filters-box .icon-download {
  float: right; }

.credit-behaviour-wrapper .filters-box .icon-download svg {
  color: #2d9086 !important; }

.credit-behaviour-wrapper .filters-box label {
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #1d1d1d; }

.credit-behaviour-wrapper .filters-box ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.credit-behaviour-wrapper .filters-box ul li {
  margin-right: 10px; }

.credit-behaviour-wrapper .filters-box ul label {
  font-size: 12px; }

.credit-behaviour-wrapper .filters-box button {
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #058373;
  background-color: transparent;
  border-radius: 15px;
  border: 1px solid #058373;
  padding: 3px 5px; }

.credit-behaviour-wrapper .deliquency-trend {
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0; }

.credit-behaviour-wrapper .deliquency-trend li {
  flex: 1;
  padding: 9px 0; }

.credit-behaviour-wrapper .text-input-field {
  resize: none;
  max-height: 100px;
  width: 90%;
  overflow: hidden;
  border-radius: 5px;
  padding: 5px; }

.boldText {
  font-weight: 600 !important;
  color: #1d1d1d;
  font-size: 14px;
  display: inline-block;
  margin-right: 1em; }

.heading {
  font-size: 12px; }

.subheading {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 2px 4px; }

.subheading .liveLoans {
  color: #058373;
  font-size: 12px;
  font-weight: 800 !important; }

.subheading .blue-text {
  color: #56D9FE; }

.subheading .blue-text .logo {
  margin-left: 4rem;
  height: 2rem; }

.subheading span {
  min-width: 45%; }

.subheading span:first-child {
  border-right: 1px solid #fff; }

.manage_user-wrapper .add_button {
  color: #4687f4;
  border: 2px solid #4687f4;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 600;
  max-width: 15%;
  margin: 2rem 0rem;
  cursor: pointer; }

.manage_user-wrapper .add_button img {
  height: 20px;
  margin-right: 12px; }

.manage_user-wrapper .user_id {
  color: #4687f4; }

.manage_user-wrapper .avatar_container {
  display: flex; }

.manage_user-wrapper .avatar_container_icon {
  border-radius: 100%;
  height: 25px;
  width: 25px;
  background-color: #5be0bf;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  margin-right: 10px; }

.manage_user-wrapper .user_select {
  border: none;
  width: 60%;
  padding: 10px;
  border-radius: 5px; }

.manage_user-wrapper .ReactTable .rt-noData {
  margin-top: 3rem; }

.manage_user-wrapper .Inactive {
  background-color: #fff7eb;
  color: #ff7d1a; }

.manage_user-wrapper .Active {
  background-color: #f4fbf4;
  color: #39b73f; }

.manage_user-wrapper .Pending {
  background-color: #ecf2fe;
  color: #4687f4; }

.manage_user-wrapper .Blocked {
  background-color: rgba(50, 50, 50, 0.1);
  color: #323232; }

.manage_user-wrapper .wavier_img {
  height: 2.2rem; }

.manage_user-wrapper .capsule {
  border-radius: 1rem;
  padding: 0.2rem 2rem; }

.manage_user-wrapper .capsule-red {
  background-color: #f8e7e7;
  color: #ca4949; }

.manage_user-wrapper .capsule-green {
  background-color: #effaf0;
  color: #39b73f; }

.manage_user-wrapper .capsule-blue {
  background-color: #b4caee;
  color: #4687f4; }

.manage_user-wrapper .card-box {
  border: 1px solid #dbdfe7;
  border-radius: 1.5rem;
  padding: 1rem;
  margin: 1rem 0rem; }

.manage_user-wrapper .text-grey {
  margin: 0.5rem 0rem; }

.manage_user-wrapper .text-bold {
  padding: 0.1rem; }

.manage_user-wrapper .button {
  color: #4687f4;
  font-size: 2.5rem;
  background-color: #ecf2fe;
  border: none;
  padding: 2px 12px;
  border-radius: 0.7rem; }

.manage_user-wrapper .button:disabled {
  cursor: not-allowed;
  background-color: #f8f8f8;
  color: #adadad; }

.invoices-wrapper .upload-file {
  display: inline-block !important;
  position: absolute;
  opacity: 0;
  cursor: pointer; }

.invoices-wrapper .heading {
  color: #058373;
  font-weight: bolder;
  font-size: 2rem;
  margin-top: 3rem; }

.invoices-wrapper .heading img {
  height: 4rem;
  cursor: pointer; }

.invoices-wrapper .text_heading {
  color: #4687f4;
  font-weight: bolder;
  font-size: 1.5rem; }

.invoices-wrapper .preview-margin-modal {
  height: 2rem;
  margin-left: 0.8rem;
  margin-bottom: 2px; }

.invoices-wrapper .button {
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 2rem 0rem 0rem 1rem;
  max-width: 15%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; }

.invoices-wrapper .button img {
  height: 2rem;
  margin-right: 10px; }

.invoices-wrapper .add_bulk_button {
  color: white;
  border: 2px solid #4687f4;
  background-color: #4687f4; }

.invoices-wrapper .add_button {
  color: #4687f4;
  border: 2px solid #4687f4; }

.invoices-wrapper .release_margin {
  margin: 0;
  padding: 1rem;
  margin-bottom: 1rem; }

.invoices-wrapper h4 {
  display: flex;
  justify-content: center;
  color: #4687f4;
  font-family: 'Heebo';
  font-size: 3rem; }

.invoices-wrapper .img-icon {
  height: 2.2rem; }

.invoices-wrapper .outline_button {
  color: #4687f4;
  font-size: 1.6rem;
  cursor: pointer; }

.invoices-wrapper .capsule {
  border-radius: 1rem;
  padding: 2px 10px;
  font-size: 1.1rem;
  text-align: center;
  min-width: 10rem;
  display: inline-block; }

.invoices-wrapper .capsule_orange {
  color: #ff7d1a;
  background-color: #fff7eb; }

.invoices-wrapper .capsule_green {
  color: #1de9b6;
  background-color: #e3fcf6; }

.invoices-wrapper .capsule_red {
  color: #ff6055;
  background-color: #ffefee; }

.invoices-wrapper .capsule_new_green {
  color: #058373;
  background-color: rgba(107, 248, 155, 0.2); }

.invoices-wrapper .capsule_blue {
  color: #4687f4;
  background-color: #e5eefb; }

.invoices-wrapper .capsule_grey {
  color: #717171ed;
  background-color: #7171711c; }

.invoices-wrapper .details_button {
  color: #4687f4;
  background-color: rgba(70, 135, 244, 0.1);
  border-radius: 5px;
  padding: 5px 10px;
  width: 7rem;
  text-align: center;
  border: none; }

.invoices-wrapper .invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.invoices-wrapper .invoice-header .invoice-btn {
  height: 2rem;
  width: 2rem;
  margin-right: 6rem;
  cursor: pointer; }

.invoices-wrapper .card-filter {
  width: 65%;
  margin-left: 4rem;
  border: 1px solid #bcbcbc;
  margin-top: -3rem;
  font-family: 'Heebo';
  color: #717171; }

.invoices-wrapper .hover {
  cursor: pointer; }

.invoices-wrapper .date-picker {
  border: 1px solid #bcbcbc;
  color: #717171;
  font-family: 'Heebo'; }

.invoices-wrapper .date-picker .date {
  background-color: #d9d9d9; }

.invoices-wrapper .button_invoices {
  padding: 0.8rem 0.2rem;
  font-weight: 500;
  max-width: none; }

.invoices-wrapper .btn {
  border: 1px solid #4687f4; }

.invoices-wrapper .btn-text {
  font-size: 1.4rem; }

.invoices-wrapper .btn-red {
  color: #fe5858;
  border: 1px solid #fe5858; }

.invoices-wrapper .btn-grey {
  color: gray;
  border: 1px solid gray; }

.invoices-wrapper .btn-green {
  color: #058373;
  border: 1px solid #058373; }

.invoices-wrapper .icon-right {
  margin-left: 9px; }

.invoices-wrapper .search-status {
  width: 40% !important; }

.invoices-wrapper .search-form {
  display: flex;
  text-align: right; }

.invoices-wrapper .search-form .select {
  border: 1px solid #4687f4;
  width: 52%;
  border-radius: 2rem;
  outline: none;
  padding: 0.5rem 1rem; }

.invoices-wrapper .search-form .select-react-table {
  width: none; }

.invoices-wrapper .search-form .drop-search {
  border-radius: 5px;
  width: 40%; }

.invoices-wrapper .search-form .input {
  width: 62%; }

.invoices-wrapper .search-form .submit {
  border-radius: 2rem;
  border: none;
  background-color: #4687f4;
  padding: 0.4rem 1.4rem;
  margin-left: -3rem;
  margin-top: 3px; }

.invoices-wrapper .search-form .submit img {
  height: 1.2rem; }

.button__wrapper {
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 14px;
  border: none;
  outline: none; }

.button__wrapper:disabled {
  cursor: not-allowed; }

.button_outlined {
  border: 1px solid #4687f4;
  color: #4687f4;
  background: transparent; }

.button_contained {
  color: #fff;
  background: #4687f4; }

.card__wrapper {
  border-radius: 15px;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.16); }

.card__wrapper .card-heading {
  border-bottom: 1px solid #e6e6e6;
  padding: 1% 2%;
  font-size: 18px; }

.card__wrapper .card-body {
  padding: 1%; }

.input-wrapper {
  margin: 10px 0; }

.input-wrapper .label-text {
  font-size: 1.2rem; }

.input-wrapper .input-generic {
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 1.2rem; }

.select-wrapper {
  margin: 10px 0; }

.select-wrapper .label-text {
  font-size: 1.2rem; }

.select-wrapper .select-generic {
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 10px; }

.eod-wrapper .eod_header {
  background-color: white;
  padding: 1px; }

.eod-wrapper .main_title {
  margin-top: 4rem; }

.eod-wrapper .main_title-primary {
  color: #058373;
  font-weight: bolder;
  font-size: 2rem; }

.eod-wrapper .main_title-primary img {
  height: 4rem;
  cursor: pointer; }

.eod-wrapper .main_title button {
  background-color: #4687f4;
  color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  padding: 0rem 1rem; }

.eod-wrapper .main_title button img {
  height: 3rem;
  margin: 0.5rem; }

.eod-wrapper .eod_card {
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(199, 199, 199, 0.16);
  font-family: 'Heebo';
  margin: 2rem 0rem;
  border: none; }

.eod-wrapper .eod_card-main-heading {
  padding: 1.2rem 3rem;
  font-size: 1.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 1rem 1rem 0rem 0rem; }

.eod-wrapper .eod_card-main-heading span {
  font-weight: 300; }

.eod-wrapper .eod_card-main-heading img {
  height: 3rem;
  margin-right: 1rem; }

.eod-wrapper .eod_card .eod_status-false {
  background-color: #f5dbdb;
  color: #ca4949; }

.eod-wrapper .eod_card .eod_status-true {
  background-color: #d3fdf8;
  color: #058373; }

.eod-wrapper .eod_card_table {
  border: 1px solid #e6e6e6; }

.eod-wrapper .eod_card_table-heading {
  padding: 1.2rem 3rem;
  font-weight: bolder;
  font-size: 1.5rem; }

.eod-wrapper .eod_card_table-content {
  padding: 2.5rem 3rem; }

.eod-wrapper .eod_card_table-content img:first-child {
  height: 3rem; }

.eod-wrapper .eod_card_table-content .image {
  height: 1rem;
  padding-left: 1rem;
  cursor: pointer; }

.eod-wrapper .eod_inner-block {
  padding: 1.2rem 3rem; }

.eod-wrapper .eod_inner-block-section {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d3d3d3;
  align-items: center; }

.eod-wrapper .eod_inner-block-section img {
  height: 4rem;
  padding: 0.8rem; }

.eod-wrapper .modal_button {
  border-radius: 0.5rem;
  width: 12rem;
  height: 4rem;
  border: none;
  outline: none;
  margin-right: 1.5rem; }

.eod-wrapper .active_button {
  color: white;
  background-color: #4687f4; }

.eod-wrapper .inactive_button {
  color: #bfbfbf;
  background-color: white;
  border: 1px solid #bfbfbf; }

.services-wrapper .buttons_wrapper {
  justify-content: center; }

.services-wrapper .buttons_wrapper .button {
  border-radius: 1rem;
  width: 16rem;
  height: 4rem;
  font-weight: bolder; }

.services-wrapper .buttons_wrapper .add_button {
  color: #4687f4;
  border: 2px solid #4687f4;
  background-color: white; }

.services-wrapper .buttons_wrapper .add_button:hover {
  color: white;
  border: 2px solid #4687f4;
  background-color: #4687f4; }

.services-wrapper .buttons_wrapper .cancel_button {
  color: #bfbfbf;
  border: 2px solid #bfbfbf;
  background-color: white; }

.services-wrapper .buttons_wrapper .cancel_button:hover {
  color: white;
  border: 2px solid #bfbfbf;
  background-color: #bfbfbf; }

.services-wrapper .user_select {
  border: none;
  width: 35%;
  padding: 10px;
  border-radius: 5px; }

.services-wrapper .On {
  background-color: #fff7eb;
  color: #ff7d1a;
  border: 1px solid #ff7d1a; }

.services-wrapper .Active {
  background-color: #f4fbf4;
  color: #39b73f;
  border: 1px solid #39b73f; }

.services-wrapper .Blocked {
  background-color: rgba(50, 50, 50, 0.1);
  color: #323232;
  border: 1px solid #323232; }

.services-wrapper .Closed {
  background-color: rgba(50, 50, 50, 0.1);
  color: #323232;
  border: 1px solid #323232; }

.services-wrapper .paragraph {
  text-align: center;
  margin: 2rem; }

.configure-transaction-wrapper .heading {
  color: #4687f4;
  font-size: 2rem;
  border-bottom: 1px solid #ecf3fe;
  padding-bottom: 1rem; }

.configure-transaction-wrapper .body {
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 1rem; }

.configure-transaction-wrapper .body .confirmation-text {
  margin: 3rem 0; }

.configure-transaction-wrapper .body .read-only-box {
  border: 1px solid #c1c1c1;
  padding: 10px;
  border-radius: 10px;
  font-size: 12px;
  margin-top: 5px;
  width: 200px;
  text-align: left;
  margin-right: 24px; }

.configure-transaction-wrapper .body .add_button {
  color: #4687f4;
  border: 1px solid #4687f4;
  background-color: white; }

.configure-transaction-wrapper .body .add_button:hover {
  color: white;
  border: 1px solid #4687f4;
  background-color: #4687f4; }

.configure-transaction-wrapper .body button {
  border-radius: 1rem;
  font-weight: bolder;
  padding: 1rem 3rem; }

.configure-transaction-wrapper .body .buttons_wrapper {
  justify-content: center; }

.configure-transaction-wrapper .body .buttons_wrapper .cancel_button {
  color: #bfbfbf;
  border: 1px solid #bfbfbf;
  background-color: white; }

.configure-transaction-wrapper .body .buttons_wrapper .cancel_button:hover {
  color: white;
  border: 1px solid #bfbfbf;
  background-color: #bfbfbf; }

.configure-transaction-wrapper .body .row {
  border-bottom: 1px solid #ecf3fe;
  padding-bottom: 2rem;
  margin: 2rem 0;
  display: flex;
  align-items: center; }

.configure-transaction-wrapper .body .row .left-section {
  width: 40%;
  margin-right: 2rem;
  color: #565656;
  text-align: left; }

.configure-transaction-wrapper .body .row .right-section {
  width: 55%;
  display: flex;
  align-items: center; }

.configure-transaction-wrapper .body .row .right-section input {
  width: 40%;
  margin-right: 2rem;
  border: 1px solid #c1c1c1; }

.configure-transaction-wrapper .body .row .right-section .helper-text {
  color: #7a7a7a; }

.configure-transaction-wrapper h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50rem;
  color: #4687f4;
  font-family: 'Heebo';
  font-size: 3rem; }

.cam_wrapper table {
  font-family: 'Heebo'; }

.cam_wrapper table th {
  padding: 1.5rem; }

.cam_wrapper table td {
  padding: 1.5rem;
  color: #474747; }

.cam_wrapper table thead {
  padding: 1.5rem;
  color: #adadad;
  box-shadow: 0rem 0.5rem 2rem rgba(156, 189, 243, 0.13);
  border-radius: 2rem; }

.cam_wrapper table tbody tr:nth-child(even) {
  background: #f7f9fc; }

.cam_wrapper .input_boxes {
  display: flex; }

.cam_wrapper .input_boxes .w-20 {
  width: 30%; }

.cam_wrapper .input_boxes .w-70 {
  width: 70%; }

.cam_wrapper_heading {
  color: #058373;
  font-weight: bold;
  font-size: 1.8rem;
  background-color: white; }

.cam_wrapper_result {
  border-radius: 0.5rem; }

.cam_wrapper_result_pass {
  color: #058373;
  background-color: #ecf9f1;
  padding: 0.5rem 2rem;
  margin: 1rem; }

.cam_wrapper_result_fail {
  color: #ff6055;
  background-color: #ffefee;
  padding: 0.5rem 2rem;
  margin: 1rem; }

.cam_wrapper_result_hold {
  color: white;
  background-color: #adadad80;
  padding: 0.5rem 3rem;
  margin: 1rem; }

.cam_wrapper_border {
  border-top: 2rem solid white;
  border-bottom: 2rem solid white; }

.cam_wrapper .limit-parameters {
  font-weight: 400;
  color: #058373;
  font-family: 'Heebo';
  margin: 4px 0px 0px 2px;
  font-size: 12px; }

.cam_wrapper .button_box {
  display: flex;
  justify-content: center; }

.cam_wrapper .button_box .button {
  border-radius: 5px;
  width: 12rem;
  padding: 1rem;
  margin: 2rem 0rem 0rem 6rem;
  cursor: pointer; }

.cam_wrapper .button_box .button img {
  height: 2rem;
  margin-right: 10px; }

.cam_wrapper .button_box .submit_button {
  color: white;
  border: 2px solid #4687f4;
  background-color: #4687f4; }

.cam_wrapper .button_box .save_button {
  color: #4687f4;
  border: 2px solid #4687f4; }

.rules-wrapper {
  background-color: #ffffff; }

.rules-wrapper-row-one {
  margin-bottom: 2%;
  margin-left: 1rem; }

.rules-wrapper-heading {
  background-color: #ecf9f1;
  font-family: Heebo;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.6rem;
  max-width: fit-content !important;
  padding: 0.8rem 3rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-left: 1rem; }

.rules-wrapper .red {
  background-color: #ffefee; }

.rules-wrapper .sub-red {
  color: #FF6055; }

.remarks-wrapper {
  margin-top: 4rem;
  background-color: #ffffff; }

.remarks-wrapper-row {
  display: flex;
  justify-content: center;
  padding-left: 0rem 0rem;
  width: 100%; }

.remarks-wrapper .remarks-btn {
  margin-right: 1rem; }

.loan-repayment-summary {
  background-color: #ffffff;
  margin-top: 4rem;
  border-radius: 1rem; }

.loan-repayment-summary-one {
  height: 6rem;
  border-radius: 1rem; }

.loan-repayment-summary-two {
  width: 100%;
  height: 100%; }

.personal_wrapper {
  background-color: #ffffff;
  margin-top: 3rem;
  width: 100%;
  font-size: 1.4rem; }

.personal_wrapper img {
  padding: 0.2rem; }

.personal_wrapper-row {
  margin: 2rem 0rem; }

.personal_wrapper-row .box-content ul {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 1rem; }

.personal_wrapper-row .box-content ul li {
  padding: 0rem 1rem;
  border-right: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.personal_wrapper-row .box-content ul li label {
  width: 14rem;
  margin-bottom: 1rem; }

.personal_wrapper-row .box-content ul li span {
  color: #1d1d1d;
  display: flex;
  align-items: center;
  font-family: 'Heebo'; }

.personal_wrapper-row .box-content ul li span .icon {
  margin-top: -0.3rem; }

.personal_wrapper-row .box-content ul li:last-child {
  border-right: none; }

.personal_wrapper-row .personal-table {
  margin-top: -1.5rem; }

.personal_wrapper-row .personal-table table {
  width: inherit; }

.personal_wrapper-row .personal-table table td {
  padding: 1.2rem 0rem 1.2rem 0rem;
  margin-left: 0px;
  color: #474747; }

.personal_wrapper-row .personal-table tbody tr:nth-child(even) {
  background: #f7f9fc; }

.personal_wrapper .matching {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.personal_wrapper .matching img {
  height: 2.5rem; }

.live-loan-and-outstanding {
  background-color: #ffffff;
  margin-top: 3rem; }

.live-loan-and-outstanding .progress-bar {
  background-color: #058373;
  border-radius: 3rem; }

.live-loan-and-outstanding .heading {
  margin: 2rem 3rem; }

.live-loan-and-outstanding-row {
  margin: 0.5rem 0rem;
  box-shadow: 0px 0px 6px rgba(199, 199, 199, 0.16); }

.live-loan-and-outstanding .live-loan-heading-col-head {
  border-right: 1px solid #eef1f6;
  border-bottom: 1px solid #eef1f6;
  color: white;
  justify-content: center;
  display: flex;
  background-color: #058373;
  padding: 1rem;
  margin: 1rem 0rem; }

.live-loan-and-outstanding .live-loan-heading-col {
  border-right: 1px solid #eef1f6;
  border-bottom: 1px solid #eef1f6;
  justify-content: center;
  display: flex;
  padding: 1rem;
  font-family: 'Heebo'; }

.live-loan-and-outstandingrow-col2 {
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.live-loan-and-outstandingrow-col2 .circular-chart {
  height: 15rem;
  width: 15rem; }

.live-loan-and-outstandingrow-col2 .circular-text {
  position: 'absolute';
  height: '100%';
  width: '100%';
  display: 'flex';
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.live-loan-and-outstanding .progress_text {
  color: #6c757d;
  text-align: center;
  margin: 0.5rem 0rem;
  padding-right: 5rem; }

.live-loan-and-outstanding .progress-bar-new {
  width: 60%;
  height: 15%;
  margin-top: 2rem;
  margin-left: 1rem; }

.live-loan-and-outstanding .name-card-row {
  margin-top: 2%; }

.live-loan-and-outstanding .name-card-row .name-card-col {
  padding: 2% 0 1% 3%;
  margin-right: 0.5rem;
  width: fit-content; }

.live-loan-and-outstanding .name-card-row .name-card-col-head {
  font-size: 1.4rem;
  color: #adadad; }

.live-loan-and-outstanding .name-card-row .name-card-col-subhead {
  color: #1d1d1d;
  font-size: 1.3rem;
  font-family: 'Heebo';
  font-weight: 600;
  margin-top: 0.5rem; }

.trade_line_container .box-header {
  padding-bottom: 15px;
  border-radius: 15px 15px 0 0; }

.trade_line_container .box-header h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bolder;
  line-height: 1.17;
  color: #058373;
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem; }

.trade_line_container .table-box table {
  width: 100%;
  display: block;
  white-space: nowrap;
  border: 1px solid #e9e9e9; }

.trade_line_container .table-box table thead tr {
  background-color: #058373;
  color: white;
  border-radius: 0px; }

.trade_line_container .table-box table thead tr th {
  border-right: 1px solid #e9e9e9;
  padding: 1rem 2rem;
  text-align: left;
  font-weight: bold !important; }

.trade_line_container .table-box table tbody tr:nth-child(even) {
  background-color: #f7f9fc; }

.trade_line_container .table-box table tbody tr:nth-child(odd) {
  background-color: #ffffff; }

.trade_line_container .table-box table tbody tr td {
  text-align: center;
  border-right: 1px solid #e9e9e9; }

.trade_line_container .table-box table tbody tr td:last-child {
  border-right: none; }

.trade_line_container .table-box table tbody tr td b {
  font-weight: bold !important; }

.trade_line_container .table-box table tbody tr td .border_bottom {
  border-bottom: 1px solid #e9e9e9; }

.trade_line_container .table-box table tbody tr .sub_column {
  padding: 1rem 1rem; }

.banking-wrapper {
  margin-top: 30px; }

.banking-wrapper .checkbox__container {
  list-style: none;
  display: flex;
  margin: 20px 0; }

.banking-wrapper .checkbox__container li {
  margin-right: 10px; }

.banking-wrapper .btns-box button {
  width: 100%;
  margin-bottom: 10px;
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.banking-wrapper .btns-box button div {
  margin-right: 5px; }

.banking-wrapper .nav-tabs {
  margin: 0 30px;
  border-color: #bcbcbc; }

.banking-wrapper .nav-tabs > li {
  margin: 0 4px -1px;
  background-color: #e5e5e5;
  border-radius: 5px 5px 0 0;
  position: relative; }

.banking-wrapper .nav-tabs > li a {
  color: #1d1d1d;
  border-radius: 5px 5px 0 0;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: 0;
  padding: 12px; }

.banking-wrapper .nav-tabs > li a > div {
  padding: 10px 20px; }

.banking-wrapper .nav-tabs > li a label {
  font-family: 'Helvetica Bold';
  margin-bottom: 0; }

.banking-wrapper .nav-tabs > li:first-child a > div {
  padding: 18px 20px; }

.banking-wrapper .nav-tabs > li.active::after {
  content: '';
  width: 75%;
  height: 2px;
  background-color: #058373;
  position: absolute;
  bottom: 0;
  left: 13%; }

.banking-wrapper .nav-tabs > li.active a {
  color: #1d1d1d;
  margin-right: 0;
  font-family: 'Helvetica Bold'; }

.banking-wrapper .child-space-between {
  display: flex;
  justify-content: space-between; }

.banking-wrapper .tab-content {
  border-right: 1px solid #bcbcbc;
  border-bottom: 1px solid #bcbcbc;
  border-left: 1px solid #bcbcbc;
  border-top: 2px solid #e5e5e5;
  border-radius: 25px;
  margin-top: -2px;
  margin-bottom: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  padding: 20px; }

.banking-wrapper .tab-content .entityName {
  font-size: 20px;
  color: #058373;
  padding: 0 15px;
  display: inline-block; }

.banking-wrapper .tab-content .actions-box {
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end; }

.banking-wrapper .tab-content .actions-box span {
  padding: 0 10px; }

.banking-wrapper .tab-content .actions-box .icon-download {
  color: #2d9086;
  cursor: pointer; }

.banking-wrapper .tab-content .actions-box .icon-download svg {
  color: #1d6f42; }

.banking-wrapper .tab-content .actions-box .icon-upload {
  color: #001061;
  cursor: pointer; }

.banking-wrapper .tab-content .actions-box .icon-download-pdf svg {
  color: red; }

.banking-wrapper .tab-content .box-content {
  background-color: #fafafa;
  border-radius: 12px;
  padding: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }

.banking-wrapper .tab-content .box-content .box-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  align-items: center;
  padding-bottom: 5px; }

.banking-wrapper .tab-content .box-content .box-header .box-heading {
  margin-top: 0;
  font-family: 'Helvetica Bold';
  font-size: 16px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #058373; }

.banking-wrapper .tab-content .box-content .box-header .actions {
  display: flex; }

.banking-wrapper .tab-content .box-content .box-header .actions .download-content {
  display: flex;
  justify-content: center;
  line-height: 1;
  font-family: 'Helvetica';
  text-align: center;
  color: #1d1d1d;
  align-items: center; }

.banking-wrapper .tab-content .box-content .box-header .actions .download-content span,
.banking-wrapper .tab-content .box-content .box-header .actions .download-content div {
  padding-right: 5px; }

.banking-wrapper .tab-content .box-content .box-header .actions .download-content span {
  display: inline-block;
  max-width: 75px; }

.banking-wrapper .tab-content .box-content .box-header .actions .download-content span.icon-pdf {
  color: #ed5a5a; }

.banking-wrapper .tab-content .box-content .box-header .actions .download-content span.icon-excel {
  color: #8bd591; }

.banking-wrapper .tab-content .box-content .box-header .actions .download-content div {
  cursor: pointer; }

.banking-wrapper .tab-content .box-content .box-header .edit-icon {
  color: #5f5f5f;
  border-bottom: 1px solid #5f5f5f;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 3px; }

.banking-wrapper .tab-content .box-content .box-header .view-flags {
  color: #ff4444;
  position: absolute;
  right: 35px;
  top: 25px;
  cursor: pointer; }

.banking-wrapper .tab-content .box-content .box-header .view-flags.disable {
  color: #b9b9b9;
  cursor: not-allowed; }

.banking-wrapper .tab-content .box-content .bank-details-list {
  list-style: none;
  margin: 10px 0; }

.banking-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit {
  margin-left: 10px; }

.banking-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit svg {
  color: #1d1d1d; }

.banking-wrapper .tab-content .box-content .bank-details-list .sanctionLimitEdit:hover {
  cursor: pointer; }

.banking-wrapper .tab-content .box-content .bank-details-list > li {
  display: flex;
  align-items: center;
  margin: 5px 0; }

.banking-wrapper .tab-content .box-content .bank-details-list > li label,
.banking-wrapper .tab-content .box-content .bank-details-list > li span {
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #1d1d1d;
  margin: 0;
  flex: 1; }

.banking-wrapper .tab-content .box-content .bank-details-list > li label {
  font-family: 'Helvetica Bold'; }

.banking-wrapper .tab-content .box-content .bank-details-list > li span {
  font-family: 'Helvetica'; }

.banking-wrapper .tab-content .box-content .bank-details-list > li svg {
  color: #b22929; }

.banking-wrapper .tab-content .box-content .bank-details-list > li .months-list {
  flex: 1;
  display: flex;
  list-style: none;
  width: 100%; }

.banking-wrapper .tab-content .box-content .bank-details-list > li .months-list li {
  font-family: Helvetica;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #ffffff;
  flex: 1;
  background-color: #058373;
  text-align: center;
  border-right: 0.5px solid #fff; }

.banking-wrapper .box-wrapper {
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  margin-bottom: 35px;
  padding: 25px 20px; }

.banking-wrapper .box-wrapper .radio-btns {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  margin-top: 20px; }

.banking-wrapper .box-wrapper .radio-btns label {
  margin-right: 30px;
  margin-top: 10px; }

.banking-wrapper .box-wrapper .box-header {
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between; }

.banking-wrapper .box-wrapper .box-header.tool-tip-box {
  display: flex;
  justify-content: space-between; }

.banking-wrapper .box-wrapper .box-header .tool-tip {
  margin-top: -3px; }

.banking-wrapper .box-wrapper .chart-details {
  margin-top: 20px; }

.banking-wrapper .box-wrapper .chart-details span {
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  color: #1d1d1d; }

.banking-wrapper .box-wrapper .chart-box {
  margin-top: 25px;
  min-height: 200px; }

.banking-wrapper .box-wrapper .box-footer {
  margin: 20px -20px -25px -20px;
  background-color: #058373;
  border-radius: 0 0 15px 15px; }

.banking-wrapper .box-wrapper .box-footer ul {
  display: flex;
  margin-bottom: 0; }

.banking-wrapper .box-wrapper .box-footer ul li {
  flex: 1;
  padding: 10px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.banking-wrapper .box-wrapper .box-footer ul li label {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.33;
  letter-spacing: 0;
  color: #ffffff;
  margin-bottom: 10px; }

.banking-wrapper .box-wrapper .box-footer ul li span {
  font-family: Helvetica;
  font-size: 24px;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  align-items: center; }

.banking-wrapper .box-wrapper .box-footer ul li span .icon {
  margin-top: -3px; }

.banking-wrapper .box-wrapper .box-footer ul:last-child {
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 0 15px 15px; }

.banking-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
  border-bottom-left-radius: 15px; }

.banking-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
  border-bottom-right-radius: 15px; }

.banking-wrapper .box-wrapper h4 {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: normal;
  color: #058373;
  margin-top: 0;
  font-weight: 600;
  margin-bottom: 1rem; }

.banking-wrapper .box-wrapper .select-box {
  margin-top: 15px;
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #1d1d1d;
  border: 1px solid #e0e0e0;
  padding: 3px;
  width: auto; }

.banking-wrapper .box-wrapper .ReactTable {
  margin: 20px auto 40px; }

.banking-wrapper .box-wrapper .ReactTable .rt-table {
  border-radius: 0px; }

.banking-wrapper .box-wrapper .ReactTable .sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  color: #058373; }

.banking-wrapper .box-wrapper .ReactTable .rt-thead {
  padding: 0px;
  margin: 0px; }

.banking-wrapper .box-wrapper .ReactTable .rt-thead .rt-th {
  position: relative;
  background-color: #058373;
  text-align: center;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0;
  color: #ffffff;
  padding: 12px;
  border-right-color: #fff; }

.banking-wrapper .box-wrapper .ReactTable .rt-thead .rt-th.-sort-asc::after {
  content: '\f0d7';
  position: absolute;
  font-family: FontAwesome;
  right: 9px;
  top: 10px;
  font-size: 16px; }

.banking-wrapper .box-wrapper .ReactTable .rt-thead .rt-th.-sort-desc::after {
  content: '\f0d8';
  position: absolute;
  font-family: FontAwesome;
  right: 9px;
  top: 10px;
  font-size: 16px; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody {
  overflow: visible !important; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  color: #058373; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .subIdentifier {
  position: absolute;
  top: 50px;
  left: 40px;
  color: #1d1d1d;
  font-size: 12px; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr {
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0;
  text-align: center;
  color: #1d1d1d; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
  border-right-color: transparent;
  padding: 2.5rem 1rem; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons {
  color: #fff;
  border-radius: 25px;
  width: 23px;
  height: 24px;
  display: inline-block;
  text-align: center;
  padding-top: 2px; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons .bold {
  font-weight: 700 !important; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
  background-color: #8bd591; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
  background-color: #ed5a5a; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr .rt-td:first-child {
  font-weight: 700 !important; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr.-odd {
  background-color: #f0f0f0; }

.banking-wrapper .box-wrapper .ReactTable .rt-tbody .rt-tr.-even {
  background-color: #fafafa; }

.banking-wrapper .box-wrapper .filter-box {
  text-align: center; }

.banking-wrapper .box-wrapper .filter-box select {
  width: 50%;
  border-radius: 10px;
  border: 1px solid #bcbcbc;
  margin-right: 10px;
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.14;
  letter-spacing: normal;
  color: #1d1d1d;
  padding: 7px; }

.banking-wrapper .box-wrapper .filter-box button {
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #ffffff;
  border: none;
  border-radius: 25px;
  padding: 7px 15px 9px;
  background-color: #058373;
  margin-left: 10px; }

.banking-wrapper .ratio {
  height: 37px;
  font-family: Helvetica;
  font-size: 38px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-align: left;
  color: #058373; }

.banking-wrapper .edit-ratio {
  cursor: pointer; }

.red-flag__wrapper {
  border-radius: 10px; }

.red-flag__wrapper.disable {
  display: none; }

.red-flag__wrapper::-webkit-scrollbar-track {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; }

.red-flag__wrapper::-webkit-scrollbar-thumb {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; }

.red-flag__wrapper .heading {
  font-family: Helvetica;
  font-size: 22px;
  color: #058373;
  padding-bottom: 1rem; }

.red-flag__wrapper .information__wrapper {
  position: relative; }

.red-flag__wrapper .information__wrapper .sub-heading {
  padding: 1rem 0rem;
  font-weight: 600 !important;
  font-size: 18px;
  color: #058373; }

.red-flag__wrapper .information__wrapper .uploaded-files-table .ReactTable .rt-tbody .rt-tr-group .rt-td {
  text-align: center;
  padding: 12px 4px !important; }

.red-flag__wrapper .information__wrapper .data {
  display: flex;
  position: relative;
  flex-wrap: wrap; }

.red-flag__wrapper .information__wrapper .data .info-columns {
  padding: 0.2rem 2rem 0.2rem 0rem;
  width: 24rem; }

.red-flag__wrapper .information__wrapper .data .info-columns .labels {
  font-weight: bold !important;
  font-size: 14px;
  color: #1d1d1d;
  padding: 0.2rem; }

.red-flag__wrapper .information__wrapper .data .info-columns .value {
  font-size: 18px;
  color: #1d1d1d;
  padding: 0.2rem; }

.red-flag__wrapper .information__wrapper .data .info-columns .value.match {
  color: red; }

.red-flag__wrapper .information__wrapper.border-bottom {
  border-top: 1.5px solid #bcbcbc; }

.red-flag__wrapper .information__wrapper .green {
  color: rgba(0, 205, 180, 0.57);
  position: absolute;
  top: 8px;
  right: 15px;
  transition: transform 0.5s ease-in-out; }

.red-flag__wrapper .information__wrapper .green.rotate {
  transform: rotate(180deg); }

.edit-ratio-form {
  text-align: center; }

.edit-ratio-form .input-container {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  flex-direction: column; }

.edit-ratio-form label {
  font-size: 16px;
  padding-bottom: 6px;
  text-align: left; }

.edit-ratio-form .input {
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: solid 1px #bcbcbc;
  background-color: #ffffff; }

.edit-ratio-form .center_align {
  justify-content: center; }

.edit-ratio-form .submit {
  width: 110px;
  height: 40px;
  border-radius: 25px;
  color: white;
  background-color: #29a9e0;
  border: none; }

.edit-ratio-form .span {
  font-size: 16px;
  text-align: center;
  margin: 10px;
  display: block; }

.edit-ratio-form .submit1 {
  width: 163px;
  height: 40px;
  color: white;
  background-color: #29a9e0;
  background: #058373;
  border-radius: 30px;
  border: none; }

.edit-ratio-form span {
  padding: 10px; }

.overview-applicant-modal .cross-icon {
  right: 20px !important;
  top: 5px !important; }

.overview-applicant-modal.edit-ratio-modal h3 {
  font-size: 22px;
  padding-bottom: 10px; }

.red-flag__wrapper .ReactTable {
  margin: 2px auto 0; }

.red-flag__wrapper .ReactTable .rt-thead .rt-th {
  padding: 10px; }

.red-flag__wrapper .ReactTable .rt-thead .rt-th:first-child {
  text-align: center !important;
  padding-left: 0px; }

.red-flag__wrapper .ReactTable .rt-tbody .rt-tr .rt-td {
  border-right-color: transparent;
  padding: 10px;
  text-align: center; }

.ReactModalPortal .limit-utilization__modal-table {
  margin-top: 20px;
  min-width: 100%; }

.ReactModalPortal .limit-utilization__modal-table-row {
  text-align: center;
  border-bottom: 5px solid #fff;
  background-color: #fafafa; }

.ReactModalPortal .limit-utilization__modal-table-row:first-child {
  background-color: #f0f0f0; }

.ReactModalPortal .limit-utilization__modal-table-row .table-header {
  color: #fff;
  background-color: #058373;
  font-weight: 500 !important;
  padding: 20px; }

.ReactModalPortal .limit-utilization__modal-table-row-data {
  color: #1d1d1d;
  border-right: 4px solid #fff; }

.ReactModalPortal .limit-utilization__modal-table-row-data input {
  border-radius: 10px;
  text-align: center;
  background-color: transparent;
  border: 1px solid black;
  padding: 5px; }

.ReactModalPortal .limit-utilization__modal .save-btn {
  width: 225px;
  border-radius: 25px;
  background-color: #29a9e0;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #ffffff;
  border: none;
  padding: 10px;
  margin-top: 20px;
  justify-content: center; }

.label-new {
  display: flex; }

.gst__container .ReactTable {
  position: relative;
  margin: 20px auto 40px; }

.gst__container .ReactTable .sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  color: #058373; }

.gst__container .ReactTable .bottom-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5); }

.gst__container .ReactTable .rt-table {
  border-radius: 0px; }

.gst__container .ReactTable .rt-thead {
  padding: 0px;
  margin: 0px; }

.gst__container .ReactTable .rt-thead .rt-th {
  position: relative;
  background-color: #058373;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0;
  color: #ffffff;
  padding: 12px;
  border-right-color: #fff; }

.gst__container .ReactTable .rt-thead .rt-th.-sort-asc::after {
  content: '\f0d7';
  position: absolute;
  font-family: FontAwesome;
  right: 9px;
  top: 10px;
  font-size: 16px; }

.gst__container .ReactTable .rt-thead .rt-th.-sort-desc::after {
  content: '\f0d8';
  position: absolute;
  font-family: FontAwesome;
  right: 9px;
  top: 10px;
  font-size: 16px; }

.gst__container .ReactTable .rt-tbody {
  overflow: visible !important; }

.gst__container .ReactTable .rt-tbody .sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  color: #058373; }

.gst__container .ReactTable .rt-tbody .subIdentifier {
  position: absolute;
  top: 50px;
  left: 40px;
  color: #1d1d1d;
  font-size: 12px; }

.gst__container .ReactTable .rt-tbody .rt-tr {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.25;
  letter-spacing: 0;
  text-align: center;
  color: #1d1d1d; }

.gst__container .ReactTable .rt-tbody .rt-tr .rt-td {
  border-right-color: transparent; }

.gst__container .ReactTable .rt-tbody .rt-tr .rt-td .icons {
  color: #fff;
  border-radius: 25px;
  width: 23px;
  height: 24px;
  display: inline-block;
  text-align: center;
  padding-top: 2px; }

.gst__container .ReactTable .rt-tbody .rt-tr .rt-td .icons .bold {
  font-weight: 700 !important; }

.gst__container .ReactTable .rt-tbody .rt-tr .rt-td .icons.check-icon {
  background-color: #8bd591; }

.gst__container .ReactTable .rt-tbody .rt-tr .rt-td .icons.close-icon {
  background-color: #ed5a5a; }

.gst__container .ReactTable .rt-tbody .rt-tr .rt-td:first-child {
  font-weight: 700 !important; }

.gst__container .ReactTable .rt-tbody .rt-tr.-odd {
  background-color: #f0f0f0; }

.gst__container .ReactTable .rt-tbody .rt-tr.-even {
  background-color: #fafafa; }

.gst__container .toggle__wrapper {
  display: flex;
  margin: 20px auto;
  width: fit-content;
  cursor: pointer; }

.gst__container .toggle__wrapper .active-button {
  padding: 5px 30px;
  transform: scale(1.15, 1.1);
  border-radius: 10px;
  transition: all 0.1s ease-in;
  color: #fff;
  background-color: #058373; }

.gst__container .toggle__wrapper .normal-button {
  padding: 5px 40px;
  border-radius: 10px;
  border: 2px solid #058373; }

.gst__container .gst-wrapper {
  margin-top: 30px; }

.gst__container .gst-wrapper .row.upper-section .nav-tabs {
  margin-top: 0; }

.gst__container .gst-wrapper .row.upper-section ul {
  display: flex; }

.gst__container .gst-wrapper .row.upper-section ul li a {
  height: 100%;
  padding: 0 !important; }

.gst__container .gst-wrapper .row.upper-section .tabs-heading {
  padding: 10px 15px; }

.gst__container .gst-wrapper .row.upper-section .bold-text__smaller {
  font-size: 12px;
  font-weight: bold !important; }

.gst__container .gst-wrapper .row.upper-section .margin-for-level {
  margin-top: 55px; }

.gst__container .gst-wrapper .box-wrapper {
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  margin-bottom: 35px;
  padding: 25px 20px; }

.gst__container .gst-wrapper .box-wrapper .box-header {
  border-bottom: 1px solid #e0e0e0;
  margin: 0 10px; }

.gst__container .gst-wrapper .box-wrapper .box-header img {
  height: 2rem;
  margin-right: 0.5rem; }

.gst__container .gst-wrapper .box-wrapper .box-header .heading {
  color: #4687f4; }

.gst__container .gst-wrapper .box-wrapper .box-header button {
  background-color: #058373;
  padding: 5px 10px;
  margin-bottom: 10px;
  border: none;
  color: white;
  border-radius: 20px; }

.gst__container .gst-wrapper .box-wrapper .box-header .react-switch {
  float: right;
  margin-bottom: 10px; }

.gst__container .gst-wrapper .box-wrapper .box-header .react-switch .react-switch-bg {
  width: 200px !important;
  border-radius: 12px;
  background-color: #e0e0e0; }

.gst__container .gst-wrapper .box-wrapper .box-header .quarterDataFlag {
  color: #b53629;
  border: 1px solid #b53629;
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
  float: right; }

.gst__container .gst-wrapper .box-wrapper .box-header-flex {
  display: flex;
  justify-content: space-between; }

.gst__container .gst-wrapper .box-wrapper .hsn-table {
  margin-top: 20px; }

.gst__container .gst-wrapper .box-wrapper .hsn-table h4 {
  background: #058373;
  color: #fff;
  padding: 15px 10px;
  margin-bottom: 0; }

.gst__container .gst-wrapper .box-wrapper .hsn-table ul li {
  display: flex;
  padding: 8px; }

.gst__container .gst-wrapper .box-wrapper .hsn-table ul li:nth-child(2n + 1) {
  background: #f0f0f0; }

.gst__container .gst-wrapper .box-wrapper .hsn-table ul li:nth-child(2n) {
  background: #f5f5f5; }

.gst__container .gst-wrapper .box-wrapper .hsn-table ul li span {
  margin-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.gst__container .gst-wrapper .box-wrapper .chart-box {
  margin-top: 25px;
  min-height: 200px; }

.gst__container .gst-wrapper .box-wrapper .box-footer {
  margin: 20px -20px -25px -20px;
  background-color: #058373;
  border-radius: 0 0 15px 15px; }

.gst__container .gst-wrapper .box-wrapper .box-footer ul {
  display: flex;
  margin-bottom: 0; }

.gst__container .gst-wrapper .box-wrapper .box-footer ul li {
  flex: 1;
  padding: 10px 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.gst__container .gst-wrapper .box-wrapper .box-footer ul li label {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.33;
  letter-spacing: 0;
  color: #ffffff; }

.gst__container .gst-wrapper .box-wrapper .box-footer ul li > span {
  font-family: Helvetica;
  font-size: 28px;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-top: 10px; }

.gst__container .gst-wrapper .box-wrapper .box-footer ul li > span .icon {
  margin-top: -3px; }

.gst__container .gst-wrapper .box-wrapper .box-footer ul:last-child {
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 0 15px 15px; }

.gst__container .gst-wrapper .box-wrapper .box-footer ul:last-child li:first-child {
  border-bottom-left-radius: 15px; }

.gst__container .gst-wrapper .box-wrapper .box-footer ul:last-child li:last-child {
  border-bottom-right-radius: 15px; }

.gst__container .gst-wrapper .box-wrapper h4 {
  font-size: 18px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #058373;
  margin-top: 0;
  font-weight: 600;
  margin-bottom: 1rem; }

.gst__container .gst-wrapper .box-wrapper .manual-gst {
  font-size: 13px;
  color: #058373;
  margin-top: 2px; }

.gst__container .gst-wrapper .box-wrapper .img-icon {
  height: 2rem;
  margin-right: 0.5rem; }

.gst__container .gst-wrapper .box-wrapper .select-box {
  margin-top: 15px;
  font-family: Helvetica;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #1d1d1d;
  border: 1px solid #e0e0e0;
  padding: 3px;
  width: auto; }

.gst__container .gst-wrapper .box-wrapper .content-box {
  margin-top: 20px; }

.gst__container .gst-wrapper .box-wrapper .content-box .content {
  margin: 20px 0; }

.gst__container .gst-wrapper .box-wrapper .content-box .content:first-child {
  margin-top: 0; }

.gst__container .gst-wrapper .box-wrapper .content-box .content:last-child {
  margin-bottom: 0; }

.gst__container .gst-wrapper .box-wrapper .content-box .content label,
.gst__container .gst-wrapper .box-wrapper .content-box .content span {
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #1d1d1d;
  display: block; }

.gst__container .gst-wrapper .box-wrapper .content-box .content label {
  font-family: 'Helvetica Bold'; }

.gst__container .gst-wrapper .box-wrapper .content-box .content span {
  font-family: Helvetica; }

.gst__container .gst-wrapper .box-wrapper .flag-box {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.gst__container .gst-wrapper .box-wrapper .flag-box span {
  color: #c60d0d; }

.gst__container .gst-wrapper .box-wrapper .flag-box span:last-child {
  margin-left: 10px; }

.gst__container .gst-wrapper .box-wrapper .vertical-divider-left {
  position: relative; }

.gst__container .gst-wrapper .box-wrapper .vertical-divider-left::before {
  position: absolute;
  left: -10px;
  content: '';
  height: 100%;
  width: 1px;
  border: 1px solid #e0e0e0; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box {
  margin-top: 15px; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box h5 {
  text-align: center;
  font-family: 'Helvetica Bold';
  font-size: 14px;
  line-height: 0.92;
  letter-spacing: normal;
  margin-top: 0;
  margin-left: 15%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box.customer-chart .circular-progressbar {
  border-radius: 50px;
  border: 2px solid #36ddd1; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box.customer-chart .funnel-chart ul li > span > span {
  background-color: #36ddd1; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box.vendor-chart .circular-progressbar {
  border-radius: 50px;
  border: 2px solid #56d9fe; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box.vendor-chart .funnel-chart ul li > span > span {
  background-color: #56d9fe; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box.product-chart .circular-progressbar {
  border-radius: 50px;
  border: 2px solid #3670cc; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box.product-chart .funnel-chart ul li > span > span {
  background-color: #3670cc; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .circular-progressbar {
  width: 100px;
  margin: 10px 0 20px 45px; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .circular-progressbar .CircularProgressbar-trail {
  stroke: transparent;
  box-shadow: 2px 2px 2px 2px; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart {
  border-right: 1px solid #e0e0e0; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul {
  width: 85%;
  margin: 0 auto; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li {
  display: flex; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li label {
  width: 130px;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1;
  letter-spacing: normal;
  color: #1d1d1d;
  margin-bottom: 0;
  border-right: 1px solid #e0e0e0;
  padding-top: 3px;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 5px;
  text-align: start;
  word-wrap: break-word; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li > span {
  display: flex;
  margin-bottom: 5px; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li > span > span {
  display: block;
  margin: 0 auto;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  color: #ffffff;
  padding: 3px 0; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:first-child > span > span {
  width: 100%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(2) > span > span {
  width: 90%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(3) > span > span {
  width: 80%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(4) > span > span {
  width: 70%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(5) > span > span {
  width: 60%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(6) > span > span {
  width: 50%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(7) > span > span {
  width: 40%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(8) > span > span {
  width: 30%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:nth-child(9) > span > span {
  width: 20%; }

.gst__container .gst-wrapper .box-wrapper .funnel-chart-box .funnel-chart ul li:last-child > span > span {
  width: 10%; }

.gst__container .gst-wrapper .box-wrapper.analysis-box {
  margin-top: 15px;
  margin-bottom: 0; }

.gst__container .gst-wrapper .box-wrapper.analysis-box table {
  width: 100%; }

.gst__container .gst-wrapper .box-wrapper.analysis-box table thead tr {
  background-color: #058373;
  color: #fff; }

.gst__container .gst-wrapper .box-wrapper.analysis-box table tbody tr:nth-child(even) {
  background-color: #f5f5f5; }

.gst__container .gst-wrapper .box-wrapper.analysis-box table tbody tr:nth-child(odd) {
  background-color: #f0f0f0; }

.gst__container .gst-wrapper .box-wrapper.analysis-box table td,
.gst__container .gst-wrapper .box-wrapper.analysis-box table th {
  padding: 10px 20px; }

.gst__container .gst-wrapper .box-wrapper.analysis-box p,
.gst__container .gst-wrapper .box-wrapper.analysis-box ol li {
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.19;
  letter-spacing: 0;
  color: #1d1d1d;
  margin-left: 15px; }

.gst__container .gst-wrapper .box-wrapper.analysis-box p {
  margin-top: 10px;
  margin-bottom: 5px; }

.sales_form_container {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  line-height: 1.42857143;
  color: #333; }

.sales_form_container .box-wrapper .box-header {
  padding-bottom: 1.5rem;
  border-radius: 1.5rem 1.5rem 0 0; }

.sales_form_container .box-wrapper .box-header h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.17;
  color: #058373;
  font-family: 'Heebo';
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem; }

.sales_form_container .box-wrapper .table-box {
  overflow-x: scroll; }

.sales_form_container .box-wrapper .table-box table {
  width: 100%;
  display: block;
  white-space: nowrap;
  border: 1px solid #e9e9e9; }

.sales_form_container .box-wrapper .table-box table thead tr {
  background-color: #058373;
  color: white;
  border-radius: 0px; }

.sales_form_container .box-wrapper .table-box table thead tr th {
  border-right: 1px solid #e9e9e9;
  padding: 1rem 2rem;
  text-align: left;
  font-weight: bold !important; }

.sales_form_container .box-wrapper .table-box table tbody tr:nth-child(even) {
  background-color: #f7f9fc; }

.sales_form_container .box-wrapper .table-box table tbody tr:nth-child(odd) {
  background-color: #ffffff; }

.sales_form_container .box-wrapper .table-box table tbody tr td {
  text-align: left;
  border-right: 1px solid #e9e9e9; }

.sales_form_container .box-wrapper .table-box table tbody tr td:last-child {
  border-right: none; }

.sales_form_container .box-wrapper .table-box table tbody tr td b {
  font-weight: bold !important; }

.sales_form_container .box-wrapper .table-box table tbody tr td .border_bottom {
  border-bottom: 1px solid #e9e9e9; }

.sales_form_container .box-wrapper .table-box table tbody tr .sub_column {
  padding: 1rem 1rem; }

.sales_form_container .box-wrapper .table-box table tbody tr .sub_column img {
  height: 2.5rem;
  margin-left: 4rem; }

.sales_form_container .box-wrapper .color-information {
  margin-top: 3rem;
  display: flex; }

.sales_form_container .box-wrapper .color-information .color-box {
  width: 3.5rem;
  height: 3.5rem; }

.sales_form_container .box-wrapper .color-information .greater-median {
  background-color: #ecf9f1; }

.sales_form_container .box-wrapper .color-information .lesser-median {
  background-color: #ffefee; }

.sales_form_container .box-wrapper .box-footer {
  margin: 2rem -2rem -2rem -2rem;
  background-color: #058373;
  border-radius: 0 0 15px 15px;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0.1%; }

.sales_form_container .box-wrapper .box-footer ul {
  display: flex;
  margin-bottom: 0; }

.sales_form_container .box-wrapper .box-footer ul li {
  flex: 1;
  padding: 1rem 1.5rem;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.sales_form_container .box-wrapper .box-footer ul li label {
  font-family: Helvetica;
  font-size: 1.4rem;
  line-height: 1.33;
  letter-spacing: 0;
  color: #ffffff; }

.sales_form_container .box-wrapper .box-footer ul li > span {
  font-family: Helvetica;
  font-size: 2.8rem;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
  margin-top: 1rem; }

.sales_form_container .box-wrapper .box-footer ul li > span .icon {
  margin-top: -3px; }

.sales_form_container .box-wrapper .box-footer ul:last-child {
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 0 15px 15px; }

.sales_form_container .box-wrapper .box-footer ul:last-child li:first-child {
  border-bottom-left-radius: 15px; }

.sales_form_container .box-wrapper .box-footer ul:last-child li:last-child {
  border-bottom-right-radius: 15px; }

.bottom-border {
  border-bottom: 1px solid #bcbcbc !important; }

.hover {
  cursor: pointer; }

.gst_manual_update_wrapper {
  font-family: 'Heebo'; }

.gst_manual_update_wrapper .credit-behaviour-wrapper .box-wrapper .box-header {
  border-bottom: none; }

.gst_manual_update_wrapper .input-container {
  position: relative; }

.gst_manual_update_wrapper .rupee-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 1.5rem; }

.gst_manual_update_wrapper .input-container input {
  padding-left: 2.5rem; }

.gst_manual_update_wrapper .nav-tabs .nav-link.active {
  color: #058373; }

.gst_manual_update_wrapper .tab-content {
  border: none; }

.gst_manual_update_wrapper .box-header {
  padding-bottom: 1.5rem;
  border-radius: 1.5rem 1.5rem 0 0; }

.gst_manual_update_wrapper .box-header h3 {
  font-size: 1.8rem;
  color: #4687f4;
  border-bottom: none;
  padding-bottom: 1rem;
  font-family: Poppins; }

.gst_manual_update_wrapper .card-shadow {
  box-shadow: 0px 0px 20px rgba(199, 199, 199, 0.32); }

.gst_manual_update_wrapper .card__wrapper {
  box-shadow: 0px 0px 20px rgba(199, 199, 199, 0.32); }

.gst_manual_update_wrapper .card__wrapper .card-heading {
  border-bottom: none; }

.gst_manual_update_wrapper .card-body {
  padding: none; }

.gst_manual_update_wrapper .input {
  padding: 1rem 1rem; }

.gst_manual_update_wrapper .gst-details {
  margin-left: 4rem;
  font-weight: 400; }

.gst_manual_update_wrapper .month {
  font-weight: 400; }

.gst_manual_update_wrapper .value {
  font-weight: bold;
  font-size: 1.4rem; }

.gst_manual_update_wrapper .heading {
  color: #adadad;
  font-size: 1.4rem; }

.gst_manual_update_wrapper .border-top {
  border-top: 1px solid #adadad;
  padding: 1rem; }

.gst_manual_update_wrapper .summary-card {
  border: 1px solid #ecf3fe;
  border-radius: 15px;
  padding: 15px; }

.gst_manual_update_wrapper .summary-card .column {
  margin-top: 4rem; }

.gst_manual_update_wrapper .summary-card h4 {
  margin-left: 6rem !important;
  font-style: normal;
  font-weight: 500;
  font-size: 18px; }

.gst_manual_update_wrapper .summary-card .label {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem; }

.gst_manual_update_wrapper .margin-left {
  margin-left: 4rem; }

.gst_manual_update_wrapper .margin-right {
  margin-right: 4rem; }

.gst_manual_update_wrapper img {
  height: 1.8rem;
  border: 1px solid #868c8f;
  padding: 1px;
  border-radius: 5px;
  cursor: pointer; }

.gst_manual_update_wrapper ul {
  list-style: none;
  display: flex; }

.ledger-analysis-wrapper .upload-file {
  display: inline-block !important;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 3rem;
  height: 1rem; }

.ledger-analysis-wrapper .table .ReactTable .rt-thead.-header {
  padding: 0% !important; }

.ledger-analysis-wrapper .card-box {
  border: 1px solid #ecf3fe;
  border-radius: 1.5rem;
  padding: 1.8rem;
  margin: 3rem 0rem; }

.ledger-analysis-wrapper .card-heading {
  color: #058373;
  font-weight: bold;
  border-bottom: none; }

.ledger-analysis-wrapper .card-sub-heading {
  font-weight: bold;
  text-align: center;
  font-size: 1.8rem;
  border-bottom: none; }

.ledger-analysis-wrapper .card-sub-heading-orange {
  color: #ff7d1a; }

.ledger-analysis-wrapper .card-sub-heading-blue {
  color: #4687f4; }

.ledger-analysis-wrapper .card-sub-heading-green {
  color: #39b73f; }

.ledger-analysis-wrapper .basic-button {
  border-radius: 0.5rem;
  padding: 0.5rem 1.6rem;
  text-align: center;
  border: none; }

.ledger-analysis-wrapper .status-button {
  color: #058373;
  background-color: rgba(5, 131, 115, 0.1); }

.ledger-analysis-wrapper .details-button {
  color: #4687f4;
  background-color: rgba(70, 135, 244, 0.1); }

.ledger-analysis-wrapper .upload-excel-button {
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 1px solid #058373;
  color: #058373;
  background-color: transparent; }

.ledger-analysis-wrapper .icon {
  height: 1.5rem;
  margin: 0rem 0.8rem; }

.ledger-analysis-wrapper .table-wrapper {
  display: flex;
  justify-content: space-around; }

.ledger-analysis-wrapper .table-wrapper .w-100 {
  width: 100%; }

.ledger-analysis-wrapper .table-wrapper-box {
  border: 1px solid #ecf3fe;
  border-radius: 1.5rem;
  padding: 1.8rem 1rem;
  width: 48%; }

.ledger-analysis-wrapper .table-wrapper-box table {
  width: 100%; }

.ledger-analysis-wrapper .table-wrapper-box table thead tr {
  color: #adadad; }

.ledger-analysis-wrapper .table-wrapper-box table thead tr th {
  border-radius: 1rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.02);
  padding: 1rem 1.5rem; }

.ledger-analysis-wrapper .table-wrapper-box table tbody tr td:nth-child(odd) {
  width: 70%; }

.ledger-analysis-wrapper .table-wrapper-box table tbody tr td {
  padding: 1.5rem;
  color: #474747;
  width: 30%; }

.ledger-analysis-wrapper .table-wrapper-box table tbody tr:nth-child(even) {
  background: #f7f9fc; }

.ledger-analysis-wrapper .table-wrapper-heading {
  padding: 0rem 1rem 1rem 1rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  color: #717171;
  border-bottom: 1px solid #e0e0e0; }

.ledger-analysis-wrapper .table-wrapper-heading-blue {
  color: #4687f4; }

.ledger-analysis-wrapper .table-blue .ReactTable .rt-tr {
  background-color: #4687f41a; }
